import styled from '@emotion/styled'
import ReactPixel from 'react-facebook-pixel'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { css } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import useAuthCheck from '@/hooks/useAuthCheck'
import useOverlay from '@/hooks/useOverlay'
import { MODAL_KEYS } from '@/constants/modal'
import { drawProduct, getProduct } from '@/apis'
import { Product } from '@/types'
import { userState } from '@/store/auth'
import { formatNum } from '@/utils/format'
import { getProductColor } from '@/utils'

import Title from '@/components/common/Title'
import Button from '@/components/common/Button'
import Modal from '@/components/common/Modal'
import BottomSheet from '@/components/common/BottomSheet'
import ProductPurchase from '@/components/product/ProductPurchase'
import ProductTickets from '@/components/product/ProductTickets'
import Notice from '@/components/product/Notice'
import LoadingDots from '@/components/common/Loading'

const ProductDetail = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const user = useRecoilValue(userState)
  const isAuth = useAuthCheck()
  const overlay = useOverlay()

  const { id } = useParams<{ id: string }>()
  const [drawCount, setDrawCount] = useState(1)
  const [openModal, setOpenModal] = useState<String | null>(null)

  const { data: productData, isLoading } = useQuery<{ data: Product }>({
    queryKey: ['product', id],
    queryFn: () => getProduct(id as string),
  })

  const { mutate } = useMutation({
    mutationFn: ({ productId, count }: { productId: string; count: number }) =>
      drawProduct(productId, count),
    onSuccess: (drawTicket) => {
      queryClient.setQueryData(['drawnGachas', id], drawTicket.data)
      ReactPixel.trackCustom('럭키기프트 티켓 구매', {
        userId: user?.accountId,
        name: user?.name,
        phone: user?.phone,
        productId: id,
        count: drawCount,
      })
      navigate(`/product/${id}/${drawCount}/result`)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const product = productData?.data

  const maxDrawCount = useMemo(() => {
    if (user?.point && product?.productPrice) {
      return Math.floor(user.point / product.productPrice)
    }
    return 0
  }, [user?.point, product?.productPrice])

  const totalPointsRequired = useMemo(() => {
    return product ? drawCount * product.productPrice : 0
  }, [drawCount, product])

  const handleButtonClick = () => {
    if (!isAuth) {
      navigate('/login')
    } else if (!user?.phone) {
      setOpenModal(MODAL_KEYS.PHONE_NUMBER_REQUIRED)
    } else if (!user?.point || user.point < totalPointsRequired) {
      setOpenModal(MODAL_KEYS.LACK_OF_POINT)
    } else {
      overlay.open(
        <BottomSheet open={true} onClose={overlay.close}>
          <ProductPurchase
            maxDrawCount={maxDrawCount}
            drawCount={drawCount}
            totalPointsRequired={totalPointsRequired}
            handleDecrease={handleDecrease}
            handleIncrease={handleIncrease}
            handleDrawClick={() =>
              mutate({
                productId: id as string,
                count: drawCount,
              })
            }
          />
        </BottomSheet>,
      )
    }
  }

  const handleIncrease = () => {
    if (drawCount < maxDrawCount) {
      setDrawCount((prev) => prev + 1)
    }
  }

  const handleDecrease = () => {
    if (drawCount > 1) {
      setDrawCount((prev) => prev - 1)
    }
  }

  useEffect(() => {
    overlay.updateOverlay(
      <BottomSheet open={true} onClose={overlay.close}>
        <ProductPurchase
          maxDrawCount={maxDrawCount}
          drawCount={drawCount}
          totalPointsRequired={totalPointsRequired}
          handleDecrease={handleDecrease}
          handleIncrease={handleIncrease}
          handleDrawClick={() =>
            mutate({
              productId: id as string,
              count: drawCount,
            })
          }
        />
      </BottomSheet>,
    )
  }, [drawCount])

  if (isLoading || !product) return <LoadingDots />

  return (
    <>
      <Info>
        <Img src={product.productImage2} alt="상품 이미지" />

        <Desc>
          <Head>
            <Name productId={product.productId}>{product.productName}</Name>
            <Price>
              <span>₩</span>
              <p>{formatNum(product.productPrice)}</p>
            </Price>
          </Head>
        </Desc>
      </Info>

      <Notice id={product.productId} />

      <Title css={{ marginTop: '28px', marginBottom: '8px' }}>당첨 가능 티켓</Title>
      <ProductTickets product={product} productPrice={product.productPrice} isLoading={isLoading} />

      <Button variant="primary" onClick={handleButtonClick} css={fixedBtn}>
        도전
      </Button>

      {overlay.OverlayComponent()}

      {openModal === MODAL_KEYS.LACK_OF_POINT && (
        <Modal
          title="포인트가 부족합니다"
          desc="포인트를 충전해 주세요"
          onConfirm={() => navigate('/point')}
          onCancel={() => setOpenModal(null)}
          confirmText="충전"
          cancelText="확인"
        />
      )}

      {openModal === MODAL_KEYS.PHONE_NUMBER_REQUIRED && (
        <Modal
          title="휴대폰 번호를 등록해 주세요"
          desc={
            '럭키기프트의 모든 상품은\n등록한 휴대폰 번호로 발송됩니다\n\n상품 수령을 위해 핸드폰 번호를 등록해 주세요'
          }
          onConfirm={() => navigate('/profile')}
          onCancel={() => setOpenModal(null)}
          confirmText="등록"
          cancelText="확인"
        />
      )}
    </>
  )
}

export default ProductDetail

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const Img = styled.img`
  width: 60%;
  border-radius: 8px;
  aspect-ratio: 1;
  overflow: hidden;
  object-fit: cover;
`

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
`

const Name = styled.p<{ productId: number }>`
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ productId }) => getProductColor(productId)};
  color: var(--white);
  padding: 8px 16px;
  border-radius: 50px;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  span {
    font-size: 18px;
  }

  p {
    font-size: 20px;
  }
`

const fixedBtn = css`
  position: fixed;
  bottom: 20px;
  max-width: calc(var(--max-width) - 32px);
  width: calc(100% - 32px);
  z-index: 1;
  margin: 0 auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
`
