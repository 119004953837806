import styled from '@emotion/styled'
import ReactPixel from 'react-facebook-pixel'
import { useEffect, useState } from 'react'
import { loadTossPayments, TossPaymentsWidgets } from '@tosspayments/tosspayments-sdk'
import { useMutation } from '@tanstack/react-query'
import { useRecoilValue } from 'recoil'

import { userState } from '@/store/auth'
import { postOrder } from '@/apis/order'

import Button from '@/components/common/Button'

interface TossPayModuleProps {
  price?: number
  accountId?: number
  onClose?: () => void
}

const TossPayModule = ({ price = 0, accountId = 0, onClose }: TossPayModuleProps) => {
  const user = useRecoilValue(userState)
  const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null)

  const amount = {
    currency: 'KRW',
    value: price,
  }

  const { mutate } = useMutation({
    mutationFn: ({ price, account }: { price: number; account: number }) =>
      postOrder(price, account),
    onSuccess: (Order) => {
      widgets?.requestPayment({
        orderId: Order.ordersId,
        orderName: Order.ordersName,
        customerName: `${user?.name}`,
        customerMobilePhone: `${user?.phone}`,
        successUrl: `${window.location.origin}/payment/result/success/${window.location.search}`,
        failUrl: `${window.location.origin}/payment/result/fail/${window.location.search}`,
      })
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const handlePay = () => {
    ReactPixel.trackCustom('럭키기프트 결제 시도', {
      userId: user?.accountId,
      name: user?.name,
      currency: 'KRW',
      value: price,
    })
    mutate({
      price: price,
      account: accountId,
    })
  }

  useEffect(() => {
    const fetchPaymentWidgets = async () => {
      const tossPayments = await loadTossPayments(`${process.env.REACT_APP_TOSS_WIDGET_CLIENT_KEY}`)
      const widgets = tossPayments.widgets({
        customerKey: `${process.env.REACT_APP_TOSS_WIDGET_CLIENT_KEY}`,
      })

      setWidgets(widgets)
    }

    fetchPaymentWidgets()
  }, [])

  useEffect(() => {
    const renderPaymentWidgets = async () => {
      if (widgets == null) {
        return
      }
      await widgets.setAmount(amount)

      await Promise.all([
        widgets.renderPaymentMethods({
          selector: '#payment-method',
          variantKey: 'DEFAULT',
        }),
        widgets.renderAgreement({
          selector: '#agreement',
          variantKey: 'AGREEMENT',
        }),
      ])
    }

    renderPaymentWidgets()
  }, [widgets])

  return (
    <Container>
      <div>
        <div id="payment-method" />
        <div id="agreement" />

        <TossBtnWrapper>
          <Button onClick={onClose} variant="secondary">
            취소
          </Button>

          <TossBtn onClick={handlePay}>결제하기</TossBtn>
        </TossBtnWrapper>
      </div>
    </Container>
  )
}

export default TossPayModule

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  width: 100%;

  > div {
    width: 100%;
    max-width: 800px;

    > div {
      width: 100%;
    }
  }
`

const TossBtnWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  gap: 10px;
`

const TossBtn = styled.button`
  width: 220%;
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  border-radius: 16px;
  background-color: #3282f6;
  border: none;
`
