import { useGoToHome } from '@/hooks/useGoToHome'

import { formatNum } from '@/utils/format'

import Button from '@/components/common/Button'

interface OrderSuccessProps {
  data: {
    ordersName: string
    totalAmount: number
  }
}

const OrderSuccess = ({ data }: OrderSuccessProps) => {
  const goToHome = useGoToHome()

  return (
    <>
      <p>결제 완료되었습니다</p>

      <div>
        <div>
          <span>선택 상품</span>
          <span>{data.ordersName}</span>
        </div>

        <div>
          <span>결제 금액</span>
          <span>{formatNum(data.totalAmount)}원</span>
        </div>
      </div>

      <Button onClick={() => goToHome()}>티켓 뽑으러 가기</Button>
    </>
  )
}

export default OrderSuccess
