import styled from '@emotion/styled'

import { LUCKY_LIST } from '@/constants'

import Button from '@/components/common/Button'

interface FortuneSelectProps {
  setStep: (step: number) => void
  luckyOption: string
  setLuckyOption: (value: string) => void
}

const FortuneSelect = ({ setStep, luckyOption, setLuckyOption }: FortuneSelectProps) => (
  <Wrapper>
    <Header>
      <Heading>운세 선택</Heading>
      <Text>
        이번 도전에서 당첨확률을 올려줄
        <br />
        당신의 운세를 선택해주세요!
      </Text>
    </Header>

    <List>
      {LUCKY_LIST.map((item, idx) => (
        <ListItem
          key={`luckyItem${idx}`}
          onClick={() => {
            setLuckyOption(item.name)
          }}
          active={luckyOption === item.name}
        >
          <Option>
            <img src={item.url} alt={item.alt} width={84} />
            <p>{item.name}</p>
          </Option>
        </ListItem>
      ))}
    </List>

    <Button variant="primary" disabled={luckyOption === ''} onClick={() => setStep(2)}>
      행운을 더해 도전!
    </Button>
  </Wrapper>
)

export default FortuneSelect

const Wrapper = styled.div`
  padding: 82px 20px 52px 20px;
`

const Header = styled.div`
  font-weight: 500;
  padding-bottom: 40px;
`

const Heading = styled.h3`
  font-size: 40px;
  color: var(--primary500);
`

const Text = styled.p`
  color: var(--primary500);
`

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px 40px;
  flex-wrap: wrap;
`

const ListItem = styled.li<{ active?: boolean }>`
  transition: transform 100ms ease-out;
  transform: ${({ active }) => (active ? 'scale(1.2)' : 'scale(1)')};
  font-weight: ${({ active }) => (active ? '500' : '400')};

  p {
    transition: all 100ms ease-out;
    padding-top: ${({ active }) => (active ? '4px' : '12px')};
  }

  cursor: pointer;
`

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
