import styled from '@emotion/styled'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import Divider from '@/components/common/Divider'

const MENU_ITEM = [
  {
    label: '회원 관리',
    items: [{ to: '/cms', label: '회원' }],
  },
  {
    label: '당첨 관리',
    items: [
      { to: '/cms/gachas', label: '당첨 내역' },
      { to: '/cms/requested', label: '수동 발급 요청' },
    ],
  },
  {
    label: '매출 관리',
    items: [{ to: '/cms/orders', label: '결제 내역' }],
  },
  {
    label: '상품 관리',
    items: [
      { to: '/cms/products', label: '박스' },
      { to: '/cms/tickets', label: '티켓' },
    ],
  },
]

interface SideBarProps {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (isOpen: boolean) => void
}

const SideBar = ({ isMobileMenuOpen, setIsMobileMenuOpen }: SideBarProps) => {
  const handleMenuClick = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false)
    }
  }

  return (
    <Wrapper isMobileMenuOpen={isMobileMenuOpen}>
      {MENU_ITEM.map((category, index) => (
        <Fragment key={category.label}>
          <Category>
            <CategoryLabel>{category.label}</CategoryLabel>
            <CategoryItems>
              {category.items.map((item) => (
                <MenuLink key={item.to} to={item.to} end onClick={handleMenuClick}>
                  {item.label}
                </MenuLink>
              ))}
            </CategoryItems>
          </Category>

          {index < MENU_ITEM.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Wrapper>
  )
}

export default SideBar

const Wrapper = styled.div<{ isMobileMenuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 180px;
  overflow: hidden;
  padding: 28px 20px 28px 20px;
  gap: 28px;

  @media (max-width: 768px) {
    display: none;

    ${({ isMobileMenuOpen }) =>
      isMobileMenuOpen &&
      `
      background-color: white;
			display: flex;
			position: absolute;
			top: 70px;
			left: 20px;
			z-index: 10;
      border-radius: 8px;
			`}
  }
`

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CategoryLabel = styled.div`
  font-size: 12px;
  cursor: default;
  color: var(--gray500);
`

const CategoryItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const MenuLink = styled(NavLink)`
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  &.active {
    color: var(--primary500);
    font-weight: 600;
  }
`
