import CryptoJS from 'crypto-js'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { User } from '@/types'

export const PIXEL_ID = process.env.REACT_APP_PIXEL_ID!
export const PIXEL_ACCESS_TOKEN = process.env.REACT_APP_PIXEL_ACCESS_TOKEN!
export const REACT_APP_PIXEL_TEST = process.env.REACT_APP_PIXEL_TEST!

export const sha256Sync = (message: string): string =>
  CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex)

interface CustomDataOptions {
  [key: string]: any
}

export const postConversionAPI = (
  eventName: string,
  customDataOptions: CustomDataOptions = {},
  user: User | null,
): void => {
  const unixTimestamp = Math.floor(Date.now() / 1000)

  axios
    .post(
      `https://graph.facebook.com/v18.0/${PIXEL_ID}/events`,
      new URLSearchParams({
        data: JSON.stringify([
          {
            event_name: eventName,
            event_id: `${eventName}-${uuidv4()}`,
            event_time: unixTimestamp,
            action_source: 'website',
            user_data: {
              client_ip_address: null,
              client_user_agent: navigator.userAgent,
              fn: user?.name ? [sha256Sync(user.name)] : [],
              em: user?.email ? [sha256Sync(user.email)] : [],
              ph: user?.phone ? [sha256Sync(user.phone)] : [],
              fbc: 'fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
              fbp: 'fb.1.1558571054389.1098115397',
            },
            custom_data: {
              currency: 'KRW',
              ...customDataOptions,
            },
          },
        ]),
        access_token: PIXEL_ACCESS_TOKEN,
        test_event_code: REACT_APP_PIXEL_TEST,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .catch((error) => {
      console.error(error)
    })
}
