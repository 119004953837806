import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import useLogout from '@/hooks/useLogout'
import useValidation from '@/hooks/useValidation'
import { userState } from '@/store/auth'
import { putProfile } from '@/apis'
import { required, email, phone } from '@/utils/validation'
import { MODAL_KEYS } from '@/constants/modal'

import Button from '@/components/common/Button'
import TextField from '@/components/common/TextField'
import Modal from '@/components/common/Modal'
import Title from '@/components/common/Title'

interface Profile {
  name: string
  email: string
  phone: string
}

const MyProfile = () => {
  const navigate = useNavigate()
  const logout = useLogout()
  const setUser = useSetRecoilState(userState)
  const user = useRecoilValue(userState)
  const [openModal, setOpenModal] = useState<String | null>(null)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [formValues, setFormValues] = useState<Profile>({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
  })

  const validationSchema = {
    name: [required('이름')],
    email: [required('이메일'), email()],
    phone: [required('번호'), phone()],
  }

  const { error, validate } = useValidation(validationSchema)

  const { mutate } = useMutation({
    mutationFn: ({ name, email, phone }: Profile) => putProfile(name, email, phone),
    onSuccess: (data) => {
      setUser(data)
      setOpenModal(MODAL_KEYS.SUCCESS)
    },
    onError: () => {
      setOpenModal(MODAL_KEYS.ERROR)
    },
  })

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
  }, [user, navigate])

  useEffect(() => {
    const formChanged =
      formValues.name !== user?.name ||
      formValues.email !== user?.email ||
      formValues.phone !== user?.phone

    setIsFormChanged(formChanged)
  }, [formValues, user])

  const handleLogout = () => {
    logout()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const onSubmit = () => {
    if (validate(formValues)) {
      mutate(formValues)
    }
  }

  if (!user) {
    return null
  }

  return (
    <>
      <Title css={{ marginBottom: '24px' }}>내 프로필</Title>

      <Form>
        <TextField
          type="text"
          width="100%"
          name="name"
          placeholder="이름을 입력해 주세요"
          value={formValues.name}
          onChange={handleChange}
        />

        <TextField
          type="text"
          width="100%"
          name="email"
          placeholder="이메일을 입력해 주세요"
          value={formValues.email}
          onChange={handleChange}
        />

        <TextField
          type="number"
          width="100%"
          name="phone"
          maxLength={30}
          placeholder="휴대폰 번호를 입력해 주세요"
          value={formValues.phone}
          onChange={handleChange}
        />

        {error && <ErrorMsg>{Object.values(error)}</ErrorMsg>}

        {!user.phone && (
          <Notice>
            ※ 당첨 상품은 등록해 주신 휴대폰 번호의 카카오톡 계정으로 기프티콘 발송되기 때문에 꼭
            본인 명의의 정확한 휴대폰 번호를 등록해 주셔야 합니다
            <br />
            <br />※ 잘못된 번호로 발송된 상품의 경우 처리가 어려울 수 있습니다
          </Notice>
        )}
      </Form>

      <BottomWrap>
        <Button
          variant="primary"
          disabled={!isFormChanged}
          css={{ marginTop: '20px' }}
          onClick={onSubmit}
        >
          수정
        </Button>

        <Button variant="textSecondary" onClick={handleLogout}>
          로그아웃
        </Button>
      </BottomWrap>

      {openModal === MODAL_KEYS.SUCCESS && (
        <Modal
          title="수정 완료되었습니다"
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )}

      {openModal === MODAL_KEYS.ERROR && (
        <Modal
          title="일시적인 오류가 발생했습니다"
          desc="잠시 후 다시 시도해주세요"
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )}
    </>
  )
}

export default MyProfile

const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`

const Notice = styled.div`
  color: var(--gray400);
  font-size: 12px;
  border: 1px solid var(--gray300);
  border-radius: 8px;
  padding: 8px;
  margin-top: 20px;
`

const BottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex: 1;
  gap: 28px;
  margin-bottom: 16px;
`

const ErrorMsg = styled.p`
  color: var(--negative);
  font-size: 12px;
`
