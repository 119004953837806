import styled from '@emotion/styled'

import { useGoToHome } from '@/hooks/useGoToHome'

import Button from '@/components/common/Button'
/*import EmptyBox from '@/icons/EmptyBox'*/

const EmptyGacha = () => {
  const goToHome = useGoToHome()

  return (
    <Container>
      <EmptyContent>
        <img
          src={`${process.env.REACT_APP_IMG_URL}/empty_box.png`}
          alt="first_step_empty_box"
          width={240}
        />

        {/*<EmptyBox />*/}
        <p>
          비어 있는 보관함에
          <br />
          초심자의 행운이 가득 합니다.
          <br />
          <br />
          좋은일이 생길 것만 같은 기분이 듭니다.
        </p>

        <Button variant="secondary" onClick={() => goToHome()}>
          지금 바로 도전하기
        </Button>
      </EmptyContent>
    </Container>
  )
}

export default EmptyGacha

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 20px 100px 20px;
  height: calc(100dvh - 274px);

  p {
    font-size: 16px;
    padding-top: 30px;
    margin-bottom: 40px;
  }
`

const EmptyContent = styled.div`
  width: 80%;
`
