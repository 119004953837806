import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getProducts } from '@/apis'
import { PRODUCT_STATUS } from '@/constants/status'
import { Product } from '@/types'
import { formatNum, formatDate } from '@/utils/format'
import { tablet, desktop } from '@/styles/commonStyles'

import Pagination from '@/components/cms/Pagination'
import TableContainer from '@/components/common/Table'
import Loading from '@/components/common/Loading'
import Card from '@/components/cms/Card'

const Products = () => {
  const [page, setPage] = useState(0)

  const { data: productsData, isLoading } = useQuery({
    queryKey: ['products', page],
    queryFn: () => getProducts(page, 10),
  })

  const products = productsData?.content || []
  const totalPages = productsData?.totalPages || 0

  if (isLoading) return <Loading />

  return (
    <>
      <TableContainer css={desktop}>
        <table>
          <thead>
            <tr>
              <th css={{ width: '5%' }}>ID</th>
              <th>이름</th>
              <th>가격</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product: Product) => (
              <tr key={product.productId}>
                <td>{product.productId}</td>
                <td>{product.productName}</td>
                <td>{formatNum(product.productPrice)}</td>
                <td>{PRODUCT_STATUS[product.productStatus as keyof typeof PRODUCT_STATUS]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      <div css={tablet}>
        {products.map((product: Product) => (
          <Card
            key={product.productId}
            id={`${product.productId}`}
            fields={[
              { label: '이름', value: product.productName },
              { label: '가격', value: `${formatNum(product.productPrice)}원` },
            ]}
            status={PRODUCT_STATUS[product.productStatus as keyof typeof PRODUCT_STATUS]}
            dateLabel="생성일"
            dateValue={formatDate(product.createdAt, 'YY-MM-DD HH:mm')}
          />
        ))}
      </div>

      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </>
  )
}

export default Products
