import styled from '@emotion/styled'

interface NoticeProps {
  id: number
}

const Notice = ({ id }: NoticeProps) => (
  <Details>
    <DescItem>
      <p>당첨 티켓</p>
      <p>뽑기 후 즉시 보관함에서 확인 가능</p>
    </DescItem>
    <DescItem>
      <p>실물 티켓</p>
      <p>보관함에서 발송 요청 후 카카오 기프티콘으로 수령</p>
    </DescItem>
    <DescItem>
      <p>확률 고지</p>
      <a href={`/product/${id}/probability`}>확률 확인</a>
    </DescItem>
  </Details>
)

export default Notice

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--gray100);
  border-radius: 16px;
  padding: 16px 20px;
  margin-top: 20px;
`

const DescItem = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  line-height: 1;
  font-size: 12px;

  p:first-of-type {
    min-width: 50px;
    color: var(--gray600);
  }

  p:last-of-type,
  a {
    color: var(--gray500);
  }
`
