import styled from '@emotion/styled'

import Input from '@/components/common/Input'
import Select from '@/components/common/Select'
import Radio from '@/components/common/Radio'

interface TextFieldProps {
  type?: string
  placeholder?: string
  unit?: string
  width?: string
  name?: string
  value?: string | number
  maxLength?: number
  disabled?: boolean

  options?: { value: number | string; label: string }[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

const TextField = ({
  type = 'text',
  placeholder,
  unit,
  width,
  name,
  options,
  value,
  maxLength,
  disabled,

  onChange,
}: TextFieldProps) => (
  <Field width={width}>
    {(type === 'text' || type === 'number') && (
      <Input
        type={type}
        placeholder={placeholder}
        unit={unit}
        width={width}
        maxLength={maxLength}
        value={value}
        disabled={disabled}
        name={name}
        onChange={onChange}
      />
    )}

    {type === 'select' && (
      <Select
        placeholder={placeholder}
        options={options}
        value={value as string}
        disabled={disabled}
        onChange={onChange}
      />
    )}

    {type === 'radio' && options && (
      <Radio name={name} options={options} value={value} disabled={disabled} onChange={onChange} />
    )}
  </Field>
)

export default TextField

const Field = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  width: ${({ width }) => width || '100%'};
`
