import { privateApi, publicApi } from '@/share/config'

export const kakaoLogin = async ({
  socialToken,
  redirectUrl,
}: {
  socialToken: string
  redirectUrl: string
}) => {
  try {
    const res = await publicApi.post(
      '/auth/signIn/kakao',
      { social_token: socialToken },
      {
        headers: { redirectUrl },
      },
    )
    return res.data
  } catch (e) {
    throw e
  }
}

export const naverLogin = async ({
  authorizationCode,
  redirectUrl,
  state,
}: {
  authorizationCode: string
  redirectUrl: string
  state: string
}) => {
  try {
    const res = await publicApi.post(
      '/auth/signIn/naver',
      { authorizationCode, state },
      {
        headers: { redirectUrl },
      },
    )
    return res.data
  } catch (e) {
    throw e
  }
}

export const refreshAccessToken = async ({ refreshToken }: { refreshToken: string }) => {
  try {
    const res = await publicApi.post(
      '/auth/refreshToken',
      {},
      {
        headers: { refreshToken },
      },
    )
    return res.data
  } catch (e) {
    throw e
  }
}

export const authCheck = async () => {
  try {
    const res = await privateApi.get('/auth/check')
    return res.data
  } catch (e) {
    throw e
  }
}
