import ReactPixel from 'react-facebook-pixel'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import useUserData from '@/hooks/useUserData'
import { postOrderSuccess } from '@/apis/order'
import { MODAL_KEYS } from '@/constants/modal'
import { postConversionAPI } from '@/share/fpixel'
import { useGoToHome } from '@/hooks/useGoToHome'
import { userState } from '@/store/auth'

import Modal from '@/components/common/Modal'
import Loading from '@/components/common/Loading'
import OrderSuccess from '@/components/product/OrderSuccess'
import OrderFail from '@/components/product/OrderFail'

const OrderResult = () => {
  const { refetchUserData } = useUserData()
  const goToHome = useGoToHome()
  const user = useRecoilValue(userState)
  const [openModal, setOpenModal] = useState<string | null>(null)

  const [searchParams] = useSearchParams('paymentKey')
  const paymentKey = searchParams.get('paymentKey') || ''
  const ordersId = searchParams.get('orderId') || ''
  const amount = searchParams.get('amount') || ''
  const paymentType = searchParams.get('paymentType') || ''
  const { status = 'fail' } = useParams<{ status: string }>()

  const { mutate, isPending, data } = useMutation({
    mutationFn: ({
      paymentKey,
      ordersId,
      amount,
      productId = '0',
      paymentType = 'NORMAL',
    }: {
      paymentKey: string
      ordersId: string
      amount: string
      productId: string
      paymentType: string
    }) => postOrderSuccess(paymentKey, ordersId, amount, productId, paymentType),
    onSuccess: () => {
      refetchUserData()
    },
    onError: () => {
      setOpenModal(MODAL_KEYS.ERROR)
    },
  })

  useEffect(() => {
    if (status === 'success') {
      ReactPixel.trackCustom('럭키기프트 결제 완료', {
        userId: user?.accountId,
        name: user?.name,
        currency: 'KRW',
        value: amount,
      })
      postConversionAPI(
        'Purchase',
        {
          value: amount,
          content_name: '포인트구매',
        },
        user,
      )
      mutate({
        paymentKey,
        ordersId,
        amount,
        productId: '0',
        paymentType,
      })
    }
  }, [ordersId])

  if (isPending || !data) return <Loading />

  return (
    <Container status={status}>
      {status === 'success' && <OrderSuccess data={data} />}

      {status === 'fail' && <OrderFail />}

      {openModal === MODAL_KEYS.ERROR && (
        <Modal
          title="잘못된 접근입니다"
          onConfirm={() => {
            setOpenModal(null)
            goToHome()
          }}
          confirmText="확인"
        />
      )}
    </Container>
  )
}

export default OrderResult

const Container = styled.div<{ status: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  p {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
  }

  > div {
    background-color: var(--gray100);
    padding: 20px;
    border-radius: 16px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    ${({ status }) => status === 'fail' && 'font-size: 14px;'}

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`
