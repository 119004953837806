import { privateApi } from '@/share/config'

export const postOrder = async (price: number, account: number) => {
  try {
    const res = await privateApi.post('/order', { price, account })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const postOrderSuccess = async (
  paymentKey: string,
  ordersId: string,
  amount: string,
  productId: string,
  paymentType: string,
) => {
  try {
    const res = await privateApi.post('/order/success', {
      paymentKey,
      ordersId,
      amount,
      productId: parseInt(productId),
      paymentType,
    })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const postOrderSuccessV2 = async (ordersId: string, paymentId: string) => {
  try {
    console.log('postOrderSuccessV2 ordersId: ', ordersId)
    console.log('postOrderSuccessV2 paymentId: ', paymentId)
    const res = await privateApi.post('/order/v2/success', {
      ordersId,
      paymentKey: paymentId,
      type: 'NORMAL',
      currency: 'KRW',
    })
    console.log('postOrderSuccessV2 res:', res)
    return res.data.data
  } catch (e) {
    throw e
  }
}
