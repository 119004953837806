import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getUsers } from '@/apis/cms'
import { PAGE_SIZE } from '@/constants'
import { PROVIDED_TYPE } from '@/constants/type'
import { ACCOUNT_STATUS } from '@/constants/status'
import { formatDate, formatNum } from '@/utils/format'
import { User } from '@/types'
import { desktop, tablet } from '@/styles/commonStyles'

import Pagination from '@/components/cms/Pagination'
import TableContainer from '@/components/common/Table'
import Loading from '@/components/common/Loading'
import Card from '@/components/cms/Card'
import Button from '@/components/common/Button'

const UsersPage = () => {
  const [page, setPage] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)

  const { data: usersData, isLoading } = useQuery({
    queryKey: ['users', page],
    queryFn: () => getUsers(page, PAGE_SIZE),
  })

  const users = usersData?.data?.data?.content || []
  const totalPages = usersData?.data?.data?.totalPages || 0
  const totalElements = usersData?.data?.data?.totalElements || 0

  const downloadCSV = async () => {
    setIsDownloading(true)
    try {
      const allUsersData = await getUsers(0, totalElements)
      console.log(allUsersData)
      const allUsers = allUsersData.data.data.content

      const headers = [
        'ID',
        '이름',
        '휴대폰',
        '이메일',
        '보유 포인트',
        '상태',
        '가입경로',
        '가입일',
      ]

      const rows = allUsers.map((user: User) => [
        user.accountId,
        user.name,
        user.phone,
        user.email,
        user.point,
        ACCOUNT_STATUS[user.status as keyof typeof ACCOUNT_STATUS],
        PROVIDED_TYPE[user.providerType as keyof typeof PROVIDED_TYPE],
        new Date(user.createdAt).toLocaleString(),
      ])

      let csvContent =
        'data:text/csv;charset=utf-8,' + [headers, ...rows].map((e) => e.join(',')).join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'users_data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (e) {
      console.error(e)
    } finally {
      setIsDownloading(false)
    }
  }

  if (isLoading) return <Loading />

  return (
    <Container>
      <Button
        onClick={downloadCSV}
        disabled={isDownloading}
        size="small"
        css={{ width: 'fit-content', padding: '0 20px', marginLeft: 'auto' }}
      >
        CSV 다운
      </Button>

      <TableContainer css={desktop}>
        <table>
          <thead>
            <tr>
              <th css={{ width: '5%' }}>ID</th>
              <th>이름</th>
              <th>휴대폰</th>
              <th>이메일</th>
              <th>보유 포인트</th>
              <th>상태</th>
              <th>가입경로</th>
              <th>가입일</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: User) => (
              <tr key={user.accountId}>
                <td>{user.accountId}</td>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.email}</td>
                <td>{formatNum(user.point)}</td>
                <td>{ACCOUNT_STATUS[user.status as keyof typeof ACCOUNT_STATUS]}</td>
                <td>{PROVIDED_TYPE[user.providerType as keyof typeof PROVIDED_TYPE]}</td>
                <td>{formatDate(user.createdAt, 'YY-MM-DD HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      <div css={tablet}>
        {users.map((user: User) => (
          <Card
            key={user.accountId}
            id={`${user.accountId}`}
            fields={[
              { label: '이름', value: user.name },
              { label: '휴대폰', value: user.phone },
              { label: '이메일', value: user.email },
              { label: '보유 포인트', value: user.point },
              {
                label: '가입경로',
                value: PROVIDED_TYPE[user.providerType as keyof typeof PROVIDED_TYPE],
              },
            ]}
            status={ACCOUNT_STATUS[user.status as keyof typeof ACCOUNT_STATUS]}
            dateLabel="가입일"
            dateValue={formatDate(user.createdAt, 'YY-MM-DD HH:mm')}
          />
        ))}
      </div>

      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </Container>
  )
}

export default UsersPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
