import styled from '@emotion/styled'

import Button from '@/components/common/Button'

interface PaginationProps {
  page: number
  totalPages: number
  onPageChange: (page: number) => void
}

const Pagination = ({ page, totalPages, onPageChange }: PaginationProps) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      onPageChange(newPage)
    }
  }

  return (
    <PaginationWrapper>
      <Button
        variant="textSecondary"
        width="100px"
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 0}
      >
        이전
      </Button>

      <span>
        {page + 1} / {totalPages}
      </span>

      <Button
        variant="textSecondary"
        width="100px"
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages - 1}
      >
        다음
      </Button>
    </PaginationWrapper>
  )
}

export default Pagination

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
