import styled from '@emotion/styled'
import { useEffect } from 'react'

const Terms = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Container>
      <Title>통합 서비스 이용약관</Title>
      <p>
        <br />
        제1장 통합 서비스 이용약관 총칙
        <br />
        <br />
        제1조 (목적)
        <br />
        <br />이 통합 서비스 이용약관(이하 “이 이용약관”이라 함)은 월드컨트리(이하 “회사”라 함)이
        운영하는 홈페이지(이하 “홈페이지”라 함, (www.lucky-gift.co.kr)와 어플리케이션(이하 “앱”이라
        함)을 통해 회원에게 제공하는 럭키기프트 서비스 이용과 관련하여 회사와 회원과의 권리, 의무 및
        책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        <br />
        <br />
        <br />
        <br />
        제2조 (용어의 정의)
        <br />
        <br />이 이용약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
        <br />
        <br />
        “럭키기프트 서비스”(이하 “서비스”라 함)라 함은 홈페이지 및 앱을 통해 회사가 회원에게
        제공하는 전자상거래 관련 서비스 및 기타 서비스를 말합니다.
        <br />
        <br />
        “럭키기프트 회원”(이하 “회원”이라 함)이라 함은 이 이용약관에 따라 회원등록을 한 자로서,
        회사가 제공하는 서비스를 이용하는 고객을 말합니다.
        <br />
        <br />
        “아이디(ID)”라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고, 회사가 승인한 문자와
        숫자의 조합을 의미합니다.
        <br />
        <br />
        “비밀번호”라 함은 회원이 본인임을 확인하고, 비밀보호를 위해 회원 자신이 정한 문자 또는
        숫자의 조합을 의미합니다.
        <br />
        <br />
        “포인트 서비스”라 함은 럭키기프트 포인트를 이용할 수 있는 무료 서비스를 말합니다.
        <br />
        <br />
        “럭키기프트 포인트”(이하 “포인트”라 함)라 함은 회원이 럭키기프트 이용권을 사용(개봉하여 당첨
        상품을 확인)한 후 이를 포인트 전환하는 기능을 사용할 경우, 회사가 회원에게 무상으로 지급하는
        재화로서, 지정된 용도에 사용할 수 있는 전자적 재화를 말합니다.
        <br />
        <br />
        “럭키기프트 서비스”라 함은 럭키기프트를 이용할 수 있는 유료 서비스를 말합니다.
        <br />
        <br />
        “프리미엄 티켓”라 함은 “럭키기프트” 이용에 따라 일정 조건을 충족한 회원을 대상으로 회사가
        제공하는 프리미엄 티켓을 이용할 수 있는 유료 서비스를 말합니다.
        <br />
        <br />
        “럭키기프트”라 함은 가상 티켓(박스)에 상품을 무작위로 넣은 후 확률에 따라 상품이 당첨되도록
        하는 확률형 추첨 서비스를 말합니다.
        <br />
        <br />
        10. “럭키기프트 이용권”이라 함은 제9호의 럭키기프트 이용을 위해 회원이 회사로부터 구매하여
        사용하는 상품을 말합니다.
        <br />
        <br />
        11. “이벤트 포인트”라 함은 이벤트 및 장애보상, 기타 회사정책으로 회사가 무상 지급한 포인트를
        말합니다.
        <br />
        <br />
        13. “무료티켓” 라 함은 회사가 회원에게 무료로 이벤트 포인트를 지급하는 서비스를 말합니다.
        <br />
        <br />
        14. “구매”라 함은 회원이 회사가 제공하는 결제 방식을 이용하여 회사가 제공하는 서비스 또는
        상품을 구매하는 행위를 말합니다.
        <br />
        <br />
        15. “환불”이라 함은 구매한 서비스 또는 상품의 결제를 취소하고, 회사가 결제대금을 환불 기준에
        따라 회원에게 환급하여 주는 것을 말합니다.
        <br />
        <br />
        16. “제휴사”라 함은 회사로부터 상품의 배송, 반품, 교환에 관한 업무를 수탁 받아 처리하는
        업체를 말합니다.
        <br />
        <br />
        17. “게시물”이라 함은 럭키기프트 이용권을 구매하여 개봉한 회원이 당첨된 상품에 대한 후기를
        작성하는 것을 말합니다.
        <br />
        <br />
        <br />
        <br />
        제3조 (약관의 명시)
        <br />
        <br />
        회사는 이 이용약관의 내용을 회원이 쉽게 알 수 있도록 홈페이지 및 앱에 게시합니다.
        <br />
        <br />
        <br />
        <br />
        제4조 (약관의 변경)
        <br />
        <br />① 회사는 「약관의 규제에 관한 법률」, 「전자상거래 등에서의 소비자보호에 관한 법률」,
        「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「소비자기본법」 등 관련법을 위배하지
        않는 범위에서 이 이용약관을 개정할 수 있습니다.
        <br />
        <br />② 회사가 이 이용약관을 변경할 경우 회사는 그 내용을 변경기준 시행일로부터 7일 이전까지
        적용일자 및 개정 내용 및 사유 등을 명시하여 홈페이지 및 앱에 공지합니다. 단, 회원에게
        불리하게 변경되거나 중대한 사항의 변경인 경우에는 그 적용일자로부터 30일 이전까지 개정 내용
        및 사유 등을 기재하여 홈페이지 및 앱에 공지하고 제6조의 방법으로 회원에게 통지하여 드립니다.
        <br />
        <br />③ 법령의 개정으로 인하여 긴급하게 이 이용약관을 변경해야 할 경우, 당사는 홈페이지 및
        앱의 공지사항을 통하여 이 이용약관이 개정된다는 사실과 개정 내용을 지체 없이 1개월 이상
        게시하고, 제2항에 규정된 통지 방법을 준용하여 회원에게 고지합니다.
        <br />
        <br />④ 본 조의 규정에 의한 변경된 약관(이하 “개정 약관”이라 함)은 원칙적으로 그 효력
        발생일로부터 유효합니다.
        <br />
        <br />⑤ 회원은 이 이용약관의 개정과 관련하여 이의가 있는 회원 탈퇴를 할 수 있습니다. 단,
        회원이 통지일로부터 개정 약관의 시행일 직전일까지 이의를 제기하거나 회원 탈퇴를 하지 않은
        경우 이 이용약관의 개정에 동의한 것으로 간주합니다.
        <br />
        <br />
        <br />
        <br />
        제5조 (약관 외 준칙)
        <br />
        <br />① 이 이용약관에서 정하지 아니한 사항과 이 이용약관의 해석에 관하여는 「전자상거래
        등에서의 소비자 보호에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및
        관련 법령 또는 상관례에 따릅니다.
        <br />
        <br />② 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 “개별약관”이라 함)을 정하여
        이를 홈페이지 또는 앱을 통해 미리 공지할 수 있습니다.
        <br />
        <br />③ 회원은 항상 이 이용약관 및 개별 약관의 내용에 변경이 있는지 여부를 주시하여야 하며,
        변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.
        <br />
        <br />
        <br />
        <br />
        제6조 (회원에 대한 통지)
        <br />
        <br />① 회사는 회원에 대한 통지를 하는 경우 홈페이지 및 앱의 공지사항 또는 문자메세지(LMS,
        SMS, MMS), 전화, 이메일, 앱 푸시등의 방법으로 할 수 있습니다.
        <br />
        <br />② 회사는 회원 전체에 대한 통지의 경우 7일 이상 홈페이지 및 앱의 공지사항에 게시하거나
        서비스 초기 연결화면 또는 팝업화면 등의 방법으로 공지할 수 있습니다. 다만, 회원 본인의
        거래와 관련하여 중대한 영향을 미치는 사항에 대해서는 제1항에 따라 문자메세지(LMS, SMS, MMS),
        이메일 등의 방법으로 개별통지 합니다.
        <br />
        <br />③ 회사가 상기와 같이 통지하였음에도 회원이 통지된 내용을 확인하지 않음으로써 회원에게
        발생한 불이익에 대해 회사는 책임을 지지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제7조 (개인정보보호)
        <br />
        <br />① 회사는 「개인정보 보호법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등
        관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위하여 노력하며, 제3자에게
        누설하거나 제공하지 않습니다. 단, 관련 법령에 의하여 국가기관 등의 요구가 있는 경우에는
        예외로 합니다.
        <br />
        <br />② 회사는 회원의 개인정보 수집 시 정상적인 서비스 제공을 위한 최소한의 정보를 수집하며,
        개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.
        <br />
        <br />③ 기타 본 조에서 정하지 않은 사항은 회사가 마련한 개인정보처리방침에 따릅니다.
        <br />
        <br />
        <br />
        <br />
        제2장 회원 관리 및 서비스
        <br />
        <br />
        제8조 (회원가입)
        <br />
        <br />① 회원으로 가입하고자 하는 고객은 회사가 정한 가입 양식에 따라 정보를 기입하고
        본인인증을 진행한 후 이 이용약관과 개인정보의 수집·제공 및 활용 동의서 등 기타 동의가 필요한
        사항에 동의함으로써 회원가입을 신청합니다. 만 14세 미만 고객은 회사 정책에 따라 법정대리인의
        동의가 있어도 회원가입 및 서비스 이용이 불가능합니다.
        <br />
        <br />② 제1항에 따른 고객의 회원가입 신청이 있는 경우 회사는 고객의 회원가입 시 타인의
        명의를 도용한 부정 가입 방지, 중복 가입 여부 확인 및 고객의 연령을 확인하기 위하여 본인인증
        서비스 사업자가 제공하는 인증방법을 통해 본인인증서비스를 제공 및 운영합니다.
        <br />
        <br />③ 회사는 회원을 보호하기 위하여 보안 및 인증 수단을 회사의 보안 정책에 따라 시행 및
        변경할 수 있습니다.
        <br />
        <br />④ 회사는 다음 각호에 해당하는 고객의 회원가입 신청에 대하여 승낙을 하지 않거나 사후에
        회원자격을 상실시킬 수 있습니다.
        <br />
        <br />
        가입신청자가 제9조에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 단, 회원자격 상실 후
        1개월이 경과한 자로서 회사의 회원재가입 승인을 얻은 경우에는 예외로 함
        <br />
        <br />
        회원 가입 시 등록한 내용에 허위, 누락, 오기 등이 있는 경우
        <br />
        <br />
        이미 가입된 회원과 회원정보가 동일한 경우
        <br />
        <br />
        제9조에 따라 회사로부터 회원자격 정지 조치를 받은 회원이 이용정지 기간 중에 회원 탈퇴를 하고
        재 신청 하는 경우
        <br />
        <br />
        기타 고객의 귀책사유로 인하여 승인이 불가능하거나 회사가 정한 정책을 위반하여 신청하는 경우
        <br />
        <br />
        회원가입 신청일 현재 만 14세 미만인 경우
        <br />
        <br />⑤ 회원가입의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.
        <br />
        <br />⑥ 회원은 회원가입 시 입력한 정보가 변경되었을 경우 그 즉시 마이페이지 내 회원정보 수정
        또는 회사 이메일 및 기타 방법으로 회사에 그 변경 사항을 알려야 합니다. 이 경우 회사는 회원이
        회원 정보 변경 요청을 아니하여 발생한 손해에 대하여 책임을 지지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제9조 (회원 탈퇴 및 자격 상실 등)
        <br />
        <br />① 회원은 회원가입 시점으로부터 24시간이 경과되어야 회원 탈퇴가 가능합니다.
        <br />
        <br />② 제1항의 조건을 충족한 회원은 언제든지 홈페이지 또는 앱을 통하여 회원 탈퇴를 요청할
        수 있으며, 회사는 회원의 요청에 따라 조속히 회원 탈퇴에 필요한 제반 절차를 수행합니다.
        <br />
        <br />③ 회원 탈퇴 시 탈퇴 요청일 현재까지 회원이 보유하던 럭키기프트 서비스 혜택, 포인트
        서비스 혜택, 포인트 및 포인트 등을 포함한 모든 권리는 자동으로 소멸되며 재가입하더라도
        복원되지 않습니다.
        <br />
        <br />④ 회원은 보유한 럭키기프트 이용권, 포인트, 쿠폰, 포인트 등을 사용 혹은 환불을 진행한
        후 탈퇴 요청을 하여야 하며, 미사용 또는 환불 절차 미진행으로 인하여 발생된 손해에 대해
        회사는 책임을 부담하지 않습니다.
        <br />
        <br />⑤ 회사는 부정한 목적의 반복적인 탈퇴와 재가입을 막기 위하여, 회원이 탈퇴한날로부터
        1개월 간 재가입을 제한할 수 있습니다. .
        <br />
        <br />⑥ 회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원 자격을 제한 및 정지시킬 수
        있습니다.
        <br />
        <br />
        회원이 사망한 경우
        <br />
        <br />
        회사를 이용하여 구입한 재화 등의 대금, 기타 회사 이용에 관련하여 회원이 부담하는 채무를
        기일에 지급하지 않는 경우
        <br />
        <br />
        회원 가입 신청 시 허위 내용을 등록한 경우
        <br />
        <br />
        다른 회원의 서비스 이용을 방해하거나 ID와 비밀번호 또는 그 개인정보를 도용하는 경우
        <br />
        <br />
        회사 또는 다른 회원의 명예를 훼손하거나 모욕하는 경우
        <br />
        <br />
        서비스 이용 시 음란물을 게재하거나 음란사이트를 링크하는 경우
        <br />
        <br />
        관련 법령 또는 이 이용약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
        <br />
        <br />
        기타 서비스의 정상적인 운영을 방해하는 경우
        <br />
        <br />
        서비스 이용과정에서 다른 회원 또는 회사에게 폭언, 협박 또는 음란한 언행 등을 하는 경우
        <br />
        <br />
        10. 서비스를 통해 재화 등을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소, 반품하여
        업무를 방해하는 경우
        <br />
        <br />
        11. 포인트 및 포인트를 부정하게 적립 또는 사용하는 등 회사가 정한 방법 또는 목적을 벗어나
        서비스를 이용한 경우
        <br />
        <br />⑦ 회사가 회원 자격을 제한 또는 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일
        이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.
        <br />
        <br />⑧ 회사가 회원 자격을 상실시키는 경우에는 최소한 30일 이상의 기간을 정하여 소명할
        기회를 부여합니다.
        <br />
        <br />
        <br />
        <br />
        제10조 (회원정보의 변경)
        <br />
        <br />① 회원은 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만 서비스 관리를
        위해 필요한 아래의 정보 등은 수정이 제한될 수 있습니다.
        <br />
        <br />
        아이디(ID)
        <br />
        <br />
        실명인증 확인을 위한 정보(이름, 핸드폰번호, 생년월일)
        <br />
        <br />
        비속어, 욕설 등 부적절한 용어의 닉네임
        <br />
        <br />② 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 마이페이지 내 나의프로필 수정
        또는 이메일 및 기타 방법으로 회사에 대하여 그 변경사항을 알려야 하며, 이를 알리지 않아
        발생한 불이익에 대하여는 회사는 책임지지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제11조 (회사의 의무)
        <br />
        <br />① 회사는 이 이용약관이 금지하거나 관련 법령 기타 공서양속에 반하는 행위를 하지 않으며,
        이 이용약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데 최선을 다합니다.
        <br />
        <br />② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함)
        보호를 위한 보안시스템을 갖추어야 하며, 개인정보처리방침을 공시하고 준수합니다.
        <br />
        <br />③ 회사는 회원이 동의하지 않는 영리목적의 광고성 앱 푸시 등을 발송하지 않습니다.
        <br />
        <br />④ 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「전자상거래 등에서의
        소비자보호에 관한 법률」, 「통신비밀보호법」, 「전기통신사업법」 등 서비스의 운영, 유지와
        관련 있는 법령을 준수합니다.
        <br />
        <br />
        <br />
        <br />
        제12조 (회원의 의무)
        <br />
        <br />① 회원은 관련 법령 및 이 이용약관에서 규정하는 사항과 회사의 이용정책 또는 서비스와
        관련한 공지사항, 기타 회사가 통지하는 사항 등을 준수하여야 하며, 회사의 업무에 방해되는
        행위를 하여서는 안됩니다.
        <br />
        <br />② 회원의 아이디, 비밀번호, 휴대폰번호 등 회원 정보의 관리책임은 회원에게 있으며, 이를
        제3자가 이용하도록 해서는 안됩니다.
        <br />
        <br />③ 회원은 자신의 명의가 도용되거나 제3자에게 부정하게 사용된 것을 인지한 경우 즉시 그
        사실을 회사에 통보하여야 합니다.
        <br />
        <br />④ 회원은 서비스를 통해 구매한 상품의 청약철회 원인이 발생한 경우 수령한 상품을 임의로
        사용하거나 훼손되도록 방치하여서는 아니 되며, 이를 위반하여 상품의 훼손이 발생하는 경우
        책임을 부담합니다.
        <br />
        <br />⑤ 회원은 서비스 이용과 관련하여 회사와 분쟁이 발생한 경우 분쟁의 해결을 위하여 성실히
        임하여야 하며, 분쟁해결의 불성실로 인하여 회사에 발생한 모든 손실, 손해를 부담하여야 합니다.
        <br />
        <br />⑥ 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
        <br />
        <br />
        회원가입 신청 또는 회원정보의 변경 시 허위 내용을 등록하는 행위
        <br />
        <br />
        회사가 제공하는 서비스에 허위 내용을 등록하거나 게시하는 행위
        <br />
        <br />
        회사가 제공하는 럭키기프트 이용권 및 포인트, 포인트 등을 다른 회원에게 재판매하거나 기타
        이를 활용하여 영업 활동을 하는 행위
        <br />
        <br />
        회사 또는 다른 회원에 대한 허위의 사실을 기재하는 행위
        <br />
        <br />
        회사나 다른 회원의 권리를 침해하는 행위
        <br />
        <br />
        다른 회원의 아이디(ID) 및 비밀번호, 개인정보를 도용하여 부당하게 서비스를 이용하는 행위
        <br />
        <br />
        「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령에 의하여 그 전송 또는 게시가
        금지되는 정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위
        <br />
        <br />
        외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
        <br />
        <br />
        회사 또는 제3자의 명예를 손상시키거나 영업을 방해하는 행위
        <br />
        <br />
        <br />
        <br />
        제13조 (회사의 면책)
        <br />
        <br />① 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애나 발생된 손해에 대하여 책임을
        지지 않습니다.
        <br />
        <br />② 회사는 회원이 다른 회원 또는 타인과 서비스를 매개로 발생한 거래나 분쟁에 대해 개입할
        의무가 없으며, 분쟁에 따른 손해를 배상할 책임도 없습니다.
        <br />
        <br />③ 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
        경우에는 서비스 제공에 관한 책임이 면제됩니다.
        <br />
        <br />④ 회원 또는 타인이 서비스와 관련하여 제공한 정보, 자료, 사실의 신뢰도, 정확성 등
        내용에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.
        <br />
        <br />⑤ 회원이 자신의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대해 회사는
        책임을 지지 않습니다. 다만 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
        <br />
        <br />⑥ 회사의 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(피연결회사)와 회원 간에
        이루어진 거래에 회사는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다. 다만 회사의
        고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
        <br />
        <br />⑦ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대하여는 관련
        법령에 특별한 규정이 없는 한 책임을 지지 않습니다.
        <br />
        <br />⑧ 회사는 고의 또는 중과실이 없는 한 다음과 같은 사항으로부터 발생하는 손해에 대해
        책임을 지지 아니합니다.
        <br />
        <br />
        회원이 입력한 정보의 허위 또는 부정확성에 기인하는 손해
        <br />
        <br />
        서비스에 대한 접속 및 이용과정에서 발생하는 개인적인 손해
        <br />
        <br />
        서비스에 대한 회원 또는 제3자의 불법적인 접속 또는 이용으로부터 발생하는 손해
        <br />
        <br />
        전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생하는 손해
        <br />
        <br />
        서비스 이용 과정에서 발생하는 명예훼손, 기타 불법행위로 인한 각종 민형사상 책임
        <br />
        <br />
        <br />
        <br />
        제14조 (서비스의 제공 및 변경)
        <br />
        <br />① 회사가 제공하는 서비스는 다음과 같습니다.
        <br />
        <br />
        럭키기프트 서비스
        <br />
        <br />
        프리미엄 티켓 서비스
        <br />
        <br />
        포인트 서비스
        <br />
        <br />
        전자상거래 서비스(통신판매중개서비스 포함) 및 이에 수반되는 기타 서비스
        <br />
        <br />
        기타 회사가 정하는 서비스 또는 업무
        <br />
        <br />② 회사는 운영상, 기술상의 필요에 따라 서비스의 전부 또는 일부를 변경할 수 있습니다.
        <br />
        <br />③ 회사는 서비스의 일부 또는 전부를 변경하고자 하는 경우, 변경일로부터 1개월 이전에
        제6조에 규정된 통지방법을 준용하여 회원에게 개별통지하며, 홈페이지 및 앱 내 공지사항에도 그
        내용을 공지합니다.
        <br />
        <br />④ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 변경할
        수 있으며 이와 관련하여 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
        않습니다. 이 경우 통지방법은 제3항에 따른 통지방법을 준용합니다.
        <br />
        <br />
        <br />
        <br />
        제15조 (서비스의 중단)
        <br />
        <br />① 회사는 컴퓨터 등 전기통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가
        발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
        <br />
        <br />② 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 회원 또는 제3자가
        입은 손해에 대하여 배상합니다. 단, 회사의 고의 또는 과실이 없음을 입증한 경우에는 그러하지
        아니합니다.
        <br />
        <br />
        <br />
        <br />
        제16조 (서비스 이용료)
        <br />
        <br />
        회사는 일부 특정 서비스나 기능을 제공할 때 이용료를 부과할 수 있으며, 그 상세한 내용은 개별
        약관, 별도 신청서를 통해 정할 수 있습니다.
        <br />
        <br />
        <br />
        <br />
        제17조 (저작권의 귀속 및 이용제한)
        <br />
        <br />① 회사가 작성한 서비스와 관련된 저작물에 대한 저작권 및 기타 일체의 지적재산권은
        회사에 귀속합니다.
        <br />
        <br />② 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 전송,
        출판, 배포, 방송, 기타 방법에 의하여 영리 목적으로 이용하거나 제3자가 이용하도록 하여서는 안
        됩니다.
        <br />
        <br />③ 홈페이지 또는 앱에 회원이 게재한 자료와 게시물에 대한 권리와 책임은 게시 당사자에게
        있습니다.
        <br />
        <br />
        <br />
        <br />
        제18조 (게시물의 관리)
        <br />
        <br />① 회사는 회원이 게시하는 내용이 다음 각 호에 해당한다고 판단될 경우 사전 통지 없이
        삭제하거나 게시자에 대하여 특정 서비스의 이용제한, 이용계약의 해지 등의 조치를 할 수
        있습니다.
        <br />
        <br />
        다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우
        <br />
        <br />
        다량의 정보를 전송 및 게시하여 서비스의 안정적 운영을 방해하는 경우
        <br />
        <br />
        허위 사실 유포 및 다른 회원 또는 타인의 의사에 반하는 게시물을 게시하는 경우
        <br />
        <br />
        공공질서 및 공서양속에 위반되는 내용인 경우
        <br />
        <br />
        제3자의 지적재산권을 침해하는 내용인 경우
        <br />
        <br />
        정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함한 경우
        <br />
        <br />
        기타 이 이용약관이나 관련 법령에 위반된다고 판단되는 경우
        <br />
        <br />② 회사는 제1항 각 호의 사유 외에도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책
        및 관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해 게시물 노출 차단 등
        임시조치를 취할 수 있습니다.
        <br />
        <br />
        <br />
        <br />
        제19조 (서비스의 종료)
        <br />
        <br />① 회사는 럭키기프트 서비스를 종료하고자 할 경우, 럭키기프트 서비스를 종료하고자 하는
        날 (“서비스 종료일”)로부터 3개월 이전에 제6조에 규정된 통지방법을 준용하여 회원에게
        개별통지하며, 홈페이지 및 앱 내 공지사항을 통해서도 그 내용을 공지합니다.
        <br />
        <br />② 서비스 종료일(사업 종목의 전환, 사업의 포기, 업체 간의 통합 등) 이후 회원은 서비스
        내 포인트 및 포인트 적립, 기타 서비스 혜택을 받지 못하며, 통지일 시점에서 기 적립된 포인트와
        포인트는 회사가 별도 지정하는 날까지 이 이용약관과 회사가 지정하는 바에 따라 사용하여야
        하며, 포인트 및 포인트 소멸일 이후 미사용 잔여본은 소멸됩니다.
        <br />
        <br />③ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 종료할
        수 있으며 이와 관련하여 관련 법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
        않습니다. 이 경우 통지방법은 제1항에 따른 통지방법을 준용합니다.
        <br />
        <br />
        <br />
        <br />
        제20조 (정보 제공 및 광고 제공)
        <br />
        <br />① 회사는 서비스의 운영과 관련하여 서비스 내 광고를 게재하거나 정보등을 공지할 수
        있습니다. 또한 수신에 동의한 회원에 한하여 문자메세지(LMS, SMS, MMS), 이메일, 앱 푸시 등의
        방법으로 광고성 정보 및 관련 정보를 전송할 수 있습니다. 이 경우 회원은 언제든지 수신을
        거절할 수 있고, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지 아니합니다.
        <br />
        <br />② 회원은 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나
        서비스에 연결될 수 있습니다.
        <br />
        <br />③ 제2항의 경우 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공하는
        서비스는 회사의 서비스 영역이 아니므로, 회사는 그 신뢰성, 안정성 등을 보장하지 않고 그로
        인한 회원의 손해에 대하여도 책임을 지지 않습니다. 다만 회사가 고의 또는 중과실로 손해의
        발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는 그러지 아니합니다.
        <br />
        <br />
        <br />
        <br />
        제3장 럭키기프트 서비스
        <br />
        <br />
        제21조 (포인트 전환 비율)
        <br />
        <br />
        럭키기프트의 포인트 전환 비율은 아래 각 호와 같습니다. 단, 회사의 운영방침에 따라 아래의
        전환 비율은 변동될 수 있으며, 이 경우 회사는 회원이 확인 할 수 있도록 홈페이지 또는 앱 내
        공지사항 및 화면에 게시합니다.
        <br />
        <br />
        당첨된 상품 가격의 50% 포인트전환
        <br />
        <br />
        <br />
        <br />
        제22조 (프리미엄 티켓)
        <br />
        <br />① 프리미엄 티켓은 럭키기프트 누적 이용 금액이 일정금액 이상인 회원을 대상으로 회사가
        제공하는 유료 서비스를 의미합니다. 이 경우 일정금액의 기준은 회원이 확인할 수 있도록 회사
        홈페이지 또는 앱 내 공지사항 등에 게시합니다.
        <br />
        <br />② 제1항에 따른 누적 이용 금액은 럭키기프트 구매 이력만 인정되며, 럭키기프트의 배송비
        결제건 또는 구매 취소 이력은 포함되지 않습니다.
        <br />
        <br />③ 프리미엄 티켓은 제1항의 조건을 충족한 즉시 이용할 수 있습니다.
        <br />
        <br />④ 프리미엄 티켓에서 회원이 S/A/B등급의 럭키기프트에 당첨되는 경우 사전 선택한
        “명품(여), 명품(남), 전자제품”의 카테고리에서 상품이 제공되며, C/D/E 등급의 럭키기프트의
        경우 카테고리와 무관한 상품이 제공됩니다.
        <br />
        <br />⑤ 회원은 럭키기프트 이용권을 사용하여 당첨 상품을 확인한 후 상품을 배송 받을 지 또는
        포인트로 전환할 지 선택할 수 있습니다.
        <br />
        <br />⑥ 프리미엄 티켓의 포인트 전환 비율은 아래와 같습니다. 단, 회사의 운영방침에 따라
        아래의 전환 비율은 변동될 수 있으며, 이 경우 회사는 회원이 확인 할 수 있도록 홈페이지 또는
        앱 내 공지사항 및 화면에 게시합니다.
        <br />
        <br />
        S/A/B등급(고가 상품) : 당첨된 상품 가격의 100% 포인트 전환
        <br />
        <br />
        C/D/E 등급 : 구매한 럭키기프트 이용권 가격의 55% 포인트 전환
        <br />
        <br />
        <br />
        <br />
        제23조 (AB확정티켓)
        <br />
        <br />① AB확정티켓는 회사가 정한 조건에 따라 일정 게이지를 획득한 회원에게 회사가 제공하는
        무료 혜택을 의미합니다. 이 경우 일정 게이지의 기준은 럭키기프트 내부 기준에 따릅니다.
        <br />
        <br />② 제1항에 따른 게이지는 럭키기프트 또는 프리미엄 티켓의 C/D/E 등급을 포인트로 전환할
        때만 제공되며, 포인트로 전환하지 않거나 또는 S/A/B등급의 럭키기프트를 포인트로 전환할 때는
        게이지가 적립되지 않습니다.
        <br />
        <br />③ 회원은 AB확정티켓를 통해 당첨된 상품의 배송비를 지불하여야만 상품을 배송받을 수
        있습니다.
        <br />
        <br />④ AB확정티켓를 통해 배송받은 상품은 교환 또는 환불이 불가능합니다. 다만 배송받은
        상품의 하자가 있는 경우에는 예외로 합니다.
        <br />
        <br />⑤ AB확정티켓는 회사의 운영방침에 따라 변경될 수 있으며, 이 경우 회사는 회원에 확인할
        수 있도록 회사 홈페이지 또는 앱 서비스 화면에 안내합니다.
        <br />
        <br />
        <br />
        <br />
        제24조 (쿠폰)
        <br />
        <br />① 쿠폰은 회사가 무상으로 발행하는 것으로 발행대상, 발행경로, 사용대상 등에 따라 구분될
        수 있으며, 할인쿠폰의 세부구분, 할인금액, 사용방법, 사용기한 및 제한에 대한 사항은 쿠폰 또는
        서비스 화면에 표시됩니다. 할인쿠폰의 종류 및 내용과 발급 여부는 "회사"의 서비스 정책에 따라
        변경될 수 있습니다.
        <br />
        <br />② 쿠폰은 포인트로 변경 또는 <Bold>현금으로 출금될 수 없으며</Bold>, 쿠폰에 표시된
        유효기간이 만료되거나 회원 자격이 상실되면 소멸합니다.
        <br />
        <br />③ 쿠폰은 타인에게 양도할 수 없으며, 부정한 목적이나 용도로 사용 또는 획득할 수
        없습니다. 이를 위반한 경우 회사는 쿠폰을 소멸시키거나 회원 자격을 제한 또는 상실키실 수
        있습니다.
        <br />
        <br />
        회원이 부정한 방법으로 쿠폰을 획득한 사실이 확인될 경우, "회사"는 이를 회수하고, 회원자격
        상실(아이디 삭제) 및 민 ∙ 형사 상의 법적 조치 등을 취할 수 있습니다.
        <br />
        <br />
        이벤트 또는 "회사"의 서비스 정책, 마케팅 및 프로모션을 통하여 지급되는 무상의 쿠폰 등의
        유효기간은 각각 별도로 설정될 수 있습니다.
        <br />
        <br />
        회원은 무상의 쿠폰 등을 그 유효기간 이내에 사용하여야 하고, 유효기간 이내에 사용하지 않을
        경우 소멸됩니다.
        <br />
        <br />
        회원은 회사에 명시된 내용 및 개별 주의사항(사용조건)을 확인하여야 할 책임이 있으며, 명시된
        사용조건에 따라서만 쿠폰을 이용하실 수 있습니다.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        제26조 (럭키기프트 이용권 구매)
        <br />
        <br />① 회사는 회원이 럭키기프트 이용권을 구매할 수 있도록 다음 각 호의 대금지급 수단을
        제공할 수 있고, 회원은 회사가 제공하는 수단으로 럭키기프트 이용권을 구매할 수 있습니다.
        <br />
        <br />
        선불카드, 직불카드, 신용카드 등의 각종 카드 결제
        <br />
        <br />
        휴대폰 결제
        <br />
        <br />
        제휴간편결제 서비스
        <br />
        <br />
        포인트 및 쿠폰에 의한 결제
        <br />
        <br />
        기타 회사가 제공하는 전자적 결제방법
        <br />
        <br />② 회원은 제1항 각 호의 결제수단을 사용함에 있어 반드시 본인 명의의 결제수단을
        사용하여야 하고, 위법한 방법 및 임의로 타인의 결제수단을 사용하여 회사, 결제수단의 적법한
        소유자, 결제 관련 제3자에게 발생한 손해에 대한 책임은 회원에게 있습니다.
        <br />
        <br />③ 회사는 회원이 럭키기프트 이용권 구매 시 사용한 결제 수단에 대하여 정당한 사용권을
        가지고 있는 지 여부를 확인할 수 있고, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나
        확인이 불가한 경우 해당 거래를 취소할 수 있습니다.
        <br />
        <br />④ 회원이 실제로 결제하는 금액은 공급 원가, 기본 이용료, 포인트 사용 등이 적용된
        금액(실 구매액)이며, 회원에게 발행되는 구매증빙서(현금영수증, 세금계산서, 신용카드매출전표
        등)는 실구매액으로 발행됩니다.
        <br />
        <br />⑤ 회사는 회원의 럭키기프트 이용권 구매 이력의 내용을 홈페이지 또는 앱을 통해
        제공합니다.
        <br />
        <br />
        <br />
        <br />
        제27조 (럭키기프트 이용권 환불)
        <br />
        <br />① 회원은 구매한 럭키기프트 이용권을 사용하기 전까지는 회사에 환불을 요청할 수
        있습니다. 다만, 아래 각 호 사유에 해당할 경우에는 청약을 철회할 수 없고, 이에 따른 환불을
        요청할 수 없습니다.
        <br />
        <br />
        회원에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우
        <br />
        <br />
        회원의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우. 예컨대, 회원의
        개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는 콘텐츠의 개봉행위가 있는 경우
        <br />
        <br />
        시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
        <br />
        <br />
        같은 성능을 지닌 재화 등으로 복제가 가능한 경우
        <br />
        <br />그 원본인 재화 등의 포장을 훼손한 경우
        <br />
        <br />② 제1항 제2호에 따라 회원은 구매한 럭키기프트 이용권을 사용(랜티켓스를 개봉하여
        당첨상품을 확인)한 후에는 환불을 요청할 수 없고, 그에 따른 불이익은 회원에게 있습니다. 다만,
        회원이 수령한 상품의 내용이 표시∙광고의 내용과 다르거나 계약내용과 다르게 이행된 경우 수령
        완료일부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 회사에 환불을
        요청할 수 있습니다.
        <br />
        <br />③ 제1항 및 제2항 단서의 환불 요청에 따라 취소 처리되어 환불 시 카드결제의 경우
        취소절차가 완료된 즉시 결제가 취소되고, 현금결제의 경우 3영업일 이내에 환불됩니다.
        <br />
        <br />④ 휴대폰 결제의 경우 당월은 결제 전체 취소(환불)만 가능하고 부분 취소(환불) 및 익월
        이후 휴대폰 결제 취소(환불)는 불가하며, 휴대폰 결제 수수료를 제한 결제금액을 현금 또는
        포인트로 지급받을 수 있습니다.
        <br />
        <br />⑤ 럭키기프트 이용권을 복합 결제(여러 결제 수단을 한번에 사용하는 것을 의미함)를 통해
        구매한 후 일부에 대하여 결제를 취소할 경우, 포인트로 결제한 금액이 먼저 전액 환불 처리되고,
        남은 취소 금액에 대하여 그 외 결제수단으로 환불 처리가 됩니다.
        <br />
        <br />⑥ 럭키기프트 이용권 구매 후 환불 요청 내역에 쿠폰이 포함되었을 경우 환불 처리 시점
        기준으로 해당 쿠폰의 유효기간이 만료되지 않았을 경우에만 반환됩니다
        <br />
        <br />
        <br />
        <br />
        제28조 (배송)
        <br />
        <br />① 본 조의 배송기간은 회원이 럭키기프트 이용권을 사용하여 당첨된 상품의 수령 요청 및
        배송비 결제 확인일의 익일을 기산일로 하여 회원이 지정한 주소로 배송이 완료되기까지의 기간을
        말합니다.
        <br />
        <br />② 회사는 상품의 배송, 반품 및 교환과 관련된 업무를 제휴사에 위탁하여 처리할 수
        있습니다.
        <br />
        <br />③ 천재지변 등 불가항력적인 사유가 발생한 경우 그 사유가 있었던 기간은 배송기간에서
        제외됩니다.
        <br />
        <br />④ 회사는 배송과 관련하여 회원이 입력한 정보에 따라 배송하므로 회원이 입력한 정보 및 그
        정보와 관련하여 발생한 일체 책임과 불이익은 전적으로 회원이 부담하여야 합니다.
        <br />
        <br />
        <br />
        <br />
        제29조 (교환 및 반품)
        <br />
        <br />① 회원은 배송받은 상품의 내용이 표시∙광고의 내용과 다르거나 계약내용과 다르게 이행된
        경우 배송완료일부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내까지 교환
        또는 반품을 신청할 수 있습니다.
        <br />
        <br />② 제1항의 경우 교환 또는 반품에 소요되는 비용은 회사가 부담합니다.
        <br />
        <br />③ 교환 신청을 하더라도 교환할 상품의 재고가 없는 경우에는 해당 교환신청은 환불로
        처리되고, 회원은 결제금액을 결제 시 이용한 결제수단으로 환급 받거나 포인트로 적립 받을 수
        있습니다.
        <br />
        <br />④ 회원이 반품신청 시 반품송장번호를 미기재하거나 반품사유에 관하여 회사에게 정확히
        통보(구두 또는 서면으로) 하지 않을 시 반품처리 및 환불이 지연될 수 있습니다.
        <br />
        <br />⑤ 반품 상품이 제휴사에 도착하고 반품사유 및 반품배송비 부담자가 확인되면 회사는 확인된
        날로부터 3영업일 이내에 환불 또는 교환 처리를 진행합니다.
        <br />
        <br />⑥ 대행사의 상품정책 변경으로 인한 당첨 상품의 판매중지 시, 동일가격 동일 카테고리
        상품으로 대체하여 전달합니다. 기존 당첨 상품과 동일 가격, 동일 카테고리의 상품으로 대체하기
        어려운 경우, 동일 가격 다른 카테고리의 상품으로 대체하는 등 회원에게 최대한 손해가 발생하지
        않도록 조치합니다.
        <br />
        <br />
        <br />
        <br />
        제30조 (포인트 제도의 운영)
        <br />
        <br />① 회원은 럭키기프트 이용권을 사용하여 당첨 상품을 확인한 후에 상품을 수령할지 또는
        포인트로 전환할 지 선택할 수 있습니다.
        <br />
        <br />② 회원은 전환 및 적립된 <Bold>포인트를 현금으로 환급받을 수 없습니다.</Bold>
        <br />
        <br />③ 포인트의 유효기간은 적립일로부터 1년(12개월)이며, 유효기간 경과 시 자동 소멸됩니다.
        <br />
        <br />④ 회사는 포인트의 소멸일로부터 30일 전에 회원의 등록된 연락처(문자, 이메일)나 앱 푸시
        등을 이용하여 회원에게 포인트의 소멸 예정 사실을 알려드립니다.
        <br />
        <br />⑤ 이벤트 포인트의 경우 회사가 별도로 유효 기간을 정할 수 있고, 회원은 회사가 정한 유효
        기간 내에서만 이벤트 포인트를 사용할 수 있습니다.
        <br />
        <br />⑥ 포인트는 럭키기프트 이용권 및 배송비 결제 용도로만 사용 가능합니다.
        <br />
        <br />
        <br />
        <br />
        제31조 (포인트의 정정, 취소 및 소멸)
        <br />
        <br />① 포인트 전환 신청 후 오류가 발생한 경우 회원은 오류 확인일로부터 30일 이내에 회사에
        정정 요구를 할 수 있으며, 회사는 정당한 요구임이 확인된 경우 정정 요구일로부터 90일 이내에
        정정하여야 합니다.
        <br />
        <br />② 해킹, 매크로 등 부정한 방법으로 포인트를 획득한 경우 해당 포인트는 회수 및 소멸되며,
        회원 자격이 상실됩니다.
        <br />
        <br />③ 회원이 포인트를 적립했으나 부정 적립, 구매 후 취소 반복, 기타 사유에 의해 포인트
        적립이 취소되는 경우 취소된 포인트 금액은 회원이 보유한 잔여 포인트 금액에서 자동
        차감됩니다.
        <br />
        <br />④ 회원이 보유한 잔여 포인트 금액이 취소 포인트 금액보다 부족한 경우, 부족한 포인트
        금액은 마이너스 포인트로 관리되며 추후 적립되는 포인트와 상계 처리됩니다.
        <br />
        <br />⑤ 회원이 획득한 포인트는 회원 탈퇴 즉시 모든 포인트는 소멸 되며, 재 가입을 하여도 소멸
        된 포인트는 복구 되지 않습니다.
        <br />
        <br />⑥ 포인트를 적립한 후 사용하지 않고 유효기간이 경과된 포인트는 일 단위로 선입선출방식에
        의하여 자동 소멸됩니다.
        <br />
        <br />
        예시)
        <br />
        <br />
        2023년 1월 01일 적립된 포인트는 2024년 1월 02일 00시로 소멸
        <br />
        <br />
        2023년 1월 15일 적립된 포인트는 2024년 1월 16일 00시로 소멸
        <br />
        <br />
        <br />
        <br />
        제32조 (포인트 적립 및 사용조건의 안내)
        <br />
        <br />
        회사는 포인트 적립 및 사용조건, 관련 제약사항이 변경될 경우 회사 홈페이지 및 앱 내
        공지사항을 통해 공지합니다.
        <br />
        <br />
        <br />
        <br />
        제4장 포인트 서비스
        <br />
        <br />
        제33조 (포인트 제도 운영)
        <br />
        <br />① 회원은 회사가 제공하는 포인트서비스의 컨텐츠 및 개별 서비스를 이용하여 구매 활동,
        이벤트 참여 등을 통하여 무상으로 포인트를 적립할 수 있습니다.
        <br />
        <br />② 포인트의 유효기간은 적립일로부터 1년(12개월)이며, 유효기간 경과 시 자동 소멸됩니다.
        <br />
        <br />③ 회원이 다음 각 호에 해당하는 경우 유효기간과 관계 없이 적립된 포인트는 자동
        소멸됩니다.
        <br />
        <br />
        회원 가입 신청 시 허위내용을 등록, 기재 혹은 고지하여 회원 자격을 상실한 경우
        <br />
        <br />
        현금 융통 등 불건전한 용도로 포인트를 이용한 경우
        <br />
        <br />
        관련 법령 또는 이 이용약관, 회원의 요청 등에 의하여 회원정보가 삭제되는 경우
        <br />
        <br />④ 포인트의 부여 및 사용에 관한 상세한 사항은 회사가 정한 정책에 따르며, 회사는
        홈페이지 또는 앱을 통하여 이를 회원에게 안내합니다.
        <br />
        <br />⑤ 포인트의 획득 방식과 금액은 회사의 정책에 따라 달라질 수 있습니다. 회사는 포인트
        관련 정책을 수립, 시행, 변경하는 경우 공지사항을 통해 안내합니다.
        <br />
        <br />⑥ 획득한 포인트는 홈페이지 및 앱 내 마이 페이지 화면에서 확인할 수 있습니다.
        <br />
        <br />⑦ 포인트는 양도, 대여, 담보의 목적으로 사용할 수 없습니다. 다만 회사가 인정하는 경우
        예외로 합니다.
        <br />
        <br />⑧ 포인트와 포인트는 혼합하여 사용할 수 없습니다.
        <br />
        <br />
        <br />
        <br />
        제34조 (포인트의 정정, 취소 및 소멸)
        <br />
        <br />① 포인트 획득이 확정된 후 오류가 발생한 경우 회원은 오류 확인일로부터 30일 이내에
        회사에 정정 요구를 할 수 있으며, 회사는 정당한 요구임이 확인된 경우 정정 요구일로부터 90일
        이내에 정정하여야 합니다.
        <br />
        <br />② 회사는 회원이 해킹, 매크로 등 부정한 방법으로 포인트를 획득한 경우 해당 포인트를
        회수 및 소멸시키고, 회원은 회원 자격을 상실합니다.
        <br />
        <br />③ 회원이 포인트를 적립했으나 부정 적립, 구매 후 최소 반복, 기타 사유에 의해 포인트
        적립이 취소되는 경우 취소된 포인트 금액은 회원이 보유한 잔여 포인트 금액에서 자동
        차감됩니다.
        <br />
        <br />④ 회원이 보유한 잔여 포인트 금액이 취소 포인트 금액보다 부족한 경우, 부족한 포인트
        금액은 마이너스 포인트로 관리되며 추후 적립되는 포인트와 상계 처리됩니다.
        <br />
        <br />⑤ 회원이 획득한 포인트는 회원 탈퇴 즉시 모든 포인트는 소멸 되며, 재 가입을 하여도 소멸
        된 포인트는 복구되지 않습니다.
        <br />
        <br />⑥ 포인트를 적립한 후 사용하지 않고 유효기간이 경과된 포인트는 일 단위로 선입선출방식에
        의하여 자동 소멸됩니다.
        <br />
        <br />
        예시)
        <br />
        <br />
        2023년 1월 01일 적립된 포인트는 2024년 1월 02일 00시로 소멸
        <br />
        <br />
        2023년 1월 15일 적립된 포인트는 2024년 1월 16일 00시로 소멸
        <br />
        <br />
        <br />
        <br />
        제35조 (포인트 적립 및 사용조건의 안내)
        <br />
        <br />
        회사는 포인트 적립 및 사용조건, 관련 제약사항을 회사 홈페이지 및 앱을 통해서 회원에게
        공지합니다.
        <br />
        <br />
        <br />
        <br />
        제5장 모바일 상품권 서비스
        <br />
        <br />
        제36조 (모바일 상품권 구매)
        <br />
        <br />① 회사는 회원이 회사의 서비스를 통해 모바일 상품권을 구매할 수 있도록 다음 각 호의
        대금지급 수단을 제공할 수 있고, 회원은 회사가 제공하는 수단을 통해 모바일 상품권을 구매할 수
        있습니다.
        <br />
        <br />
        선불카드, 직불카드, 신용카드 등의 각종 카드결제
        <br />
        <br />
        휴대폰 결제
        <br />
        <br />
        기타 회사가 제공하는 전자적 결제방법
        <br />
        <br />② 회원은 제1항 각 호의 결제수단을 사용함에 있어 반드시 본인 명의의 결제수단을
        사용하여야 하고, 위법한 방법 및 임의로 타인의 결제수단을 사용하여 회사, 결제수단의 적법한
        소유자, 결제 관련 제3자에게 발생한 손해에 대한 책임은 회원에게 있습니다.
        <br />
        <br />③ 회사는 회원이 모바일 상품권 구매 시 사용한 결제 수단에 대하여 정당한 사용권을 가지고
        있는 지 여부를 확인할 수 있고, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나 확인이
        불가한 경우 해당 거래를 취소할 수 있습니다.
        <br />
        <br />④ 회사는 회원의 모바일 상품권 구매 이력의 내용을 홈페이지 또는 앱을 통해 제공합니다.
        <br />
        <br />
        <br />
        <br />
        제37조 (모바일 상품권 이용)
        <br />
        <br />① 회사는 모바일상품권을 발행자로부터 제공받아 회원에게 판매할 수 있습니다. 회원은
        모바일 상품권을 구매하였을 경우 모바일 상품권에 따라 발행자 또는 발행자가 지정한 다른
        업체에서 모바일 상품권을 사용하실 수 있습니다.
        <br />
        <br />② 회원은 회사의 홈페이지 또는 앱에 명시된 내용 및 모바일 상품권에 기재된 주의사항을
        확인하여야 하며, 명시된 이용 조건에 따라 모바일 상품권을 이용하실 수 있습니다.
        <br />
        <br />③ 이 이용약관에서 정해지지 아니한 사항은 공정거래위원회의 ‘신유형 상품권 표준약관’의
        내용에 따릅니다.
        <br />
        <br />
        <br />
        <br />
        제38조 (모바일 상품권 유효기간)
        <br />
        <br />① 모바일 상품권의 유효기간은 모바일 상품권에 기재된 주의사항 등에서 달리 정하지 않는
        한 회원이 모바일 상품권을 구매한 날로부터 1년으로 봅니다.
        <br />
        <br />② 회원은 모바일 상품권 발행자에게 유효기간 내에는 유효기간의 연장을 요청할 수
        있습니다.
        <br />
        <br />
        <br />
        <br />
        제39조 (모바일 상품권 구매취소 및 환불)
        <br />
        <br />① 회사와 모바일 상품권의 구매에 관한 계약을 체결한 회원은 계약 내용에 관한 서면을
        교부받은 날 또는 수신 확인의 통지를 받은 날로부터 7일 이내에 청약의 철회를 할 수 있습니다.
        다만, 그 서면을 교부 또는 수신확인의 통지를 받은 때보다 모바일 상품권의 공급이 늦게 이루어진
        경우에는 모바일 상품권의 공급을 받거나 공급이 개시된 날부터 7일 이내에 청약의 철회를 할 수
        있습니다.
        <br />
        <br />② 회원은 다음 각호의 1에 해당하는 경우에는 구매취소 및 환불을 할 수 없습니다.
        <br />
        <br />
        회원의 책임 있는 사유로 모바일 상품권이 멸실 또는 훼손된 경우.
        <br />
        <br />
        회원의 사용 또는 일부 소비에 의하여 모바일 상품권의 가치가 현저히 감소한 경우
        <br />
        <br />
        시간의 경과에 의하여 재판매가 곤란할 정도로 모바일 상품권 등의 가치가 현저히 감소한 경우
        <br />
        <br />
        같은 성능을 지는 모바일 상품권 등으로 복제가 가능한 경우
        <br />
        <br />③ 제2항 제2호와 같이 회원은 구매한 모바일 상품권을 전부 또는 일부를 사용한 경우 회사에
        환불을 요청할 수 없고, 그에 따른 불이익은 회원에게 있습니다. 다만, 회원이 제공받은 모바일
        상품권이 표시·광고의 내용과 다르거나 계약내용과 다르게 이행된 경우 모바일 상품권 교부받은
        날로부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내 회사에 환불을 요청할
        수 있습니다.
        <br />
        <br />④ 회사가 이벤트, 프로모션 등을 통하여 상품권을 무상으로 제공하는 경우에는 해당
        상품권에 대한 환불은 이루어지지 않습니다.
        <br />
        <br />⑤ 제1항 및 제3항 단서의 환불 요청에 따라 취소 처리되어 환불 시 카드결제의 경우
        취소절차가 완료된 즉시 결제가 취소되고, 현금결제의 경우 3영업일 이내에 환불됩니다.
        <br />
        <br />⑥ 휴대폰 결제의 경우 당월은 결제 전체 취소(환불)만 가능하고 부분 취소(환불) 및 익월
        이후 휴대폰 결제 취소(환불)는 불가하며, 휴대폰 결제 수수료를 제한 결제금액을 현금으로
        지급받을 수 있습니다.
        <br />
        <br />⑦ 기타 환불에 대한 세부 내역은 회사의 운영정책이 정하는 바에 따릅니다.
        <br />
        <br />
        <br />
        <br />
        제6장 기타
        <br />
        <br />
        제40조 (회원의 고충처리 및 분쟁해결)
        <br />
        <br />⑧ 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 회원에게 피해가 발생한 경우
        그 피해를 보상 처리하기 위하여 피해보상 처리기구인 고객센터를 설치, 운영합니다. 회사가
        운영하는 고객센터의 운영 시간은 아래와 같습니다.
        <br />
        <br />
        운영시간 : 월요일 ~ 금요일 오전9시부터 오후6시까지 (토,일요일 및 공휴일 제외)
        <br />
        <br />
        고객센터 연락처 : <a href="tel:02-6958-9448">02-6958-9448</a>
        <br />
        <br />
        고객센터 이메일 :{' '}
        <a href="mailto:luckygiftcs@lucky-gift.co.kr">luckygiftcs@lucky-gift.co.kr</a>
        <br />
        <br />⑨ 회사는 회원으로부터 제기되는 불만사항 및 의견이 정당하다고 판단되는 경우 이를
        신속하게 처리하며, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 즉시
        통지합니다.
        <br />
        <br />⑩ 회사와 회원간에 발생한 분쟁이 원만히 해결되지 않는 경우 회사 또는 회원은 중재기관을
        통해 중재를 신청할 수 있습니다.
        <br />
        <br />
        <br />
        <br />
        제41조 (손해배상)
        <br />
        <br />① 회사 또는 회원은 이 이용약관을 위반하여 상대방에게 손해를 입힌 경우에는 이
        이용약관을 위반한 회사 또는 회원은 상대방에게 발생한 모든 손해를 배상하여야 합니다.
        <br />
        <br />② 회사 또는 회원이 서비스를 이용함에 있어 행한 불법행위나 이 이용약관 위반행위로
        인하여 상대방이 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해
        회사 또는 회원은 자신의 책임과 비용으로 상대방을 면책시켜야 하며, 면책되지 못한 경우 그로
        인하여 발생한 모든 손해를 배상하여야 합니다.
        <br />
        <br />
        <br />
        <br />
        제42조 (준거법 및 관할법원)
        <br />
        <br />① 이 이용약관의 해석 및 회사와 회원 간의 분쟁에 대하여는 대한민국의 법률을 적용합니다.
        <br />
        <br />② 이 이용약관 및 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생하여 소송이
        제기되는 경우에는 『민사소송법』에 따라 관할법원을 정합니다.
        <br />
        <br />
        <br />
        <br />
        제43조 (기타)
        <br />
        <br />이 이용약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된 계약서, 협정서, 통보서
        등과 회사의 정책변경, 법령의 제정/개정 또는 공공기관의 고시/지침 등에 의하여 회사가 회원에게
        공지하는 내용도 이 이용약관의 일부를 구성합니다.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        시행일
        <br />
        <br />이 이용약관은 2024년 10월 13일부터 시행한다.
        <br />
      </p>
    </Container>
  )
}

export default Terms

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 1.2;
`

const Title = styled.h2`
  display: flex;
  font-weight: 500;
  font-size: 18px;
`

const Bold = styled.span`
  font-weight: 500;
`
