import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import useAuthCheck from '@/hooks/useAuthCheck'
import useUserData from '@/hooks/useUserData'
import { drawProduct } from '@/apis'
import { ProductTicket } from '@/types'
import { useGoToHome } from '@/hooks/useGoToHome'

import Button from '@/components/common/Button'
import Modal from '@/components/common/Modal'
import Playing from '@/components/gacha/Playing'
import GachaResultItem from '@/components/gacha/GachaResultItem'

const GachaResult = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const isAuth = useAuthCheck()
  const { refetchUserData } = useUserData()
  const goToHome = useGoToHome()
  const { id, drawCount } = useParams<{ id: string; drawCount: string }>()
  const [isPlay, setIsPlay] = useState<boolean>(false)
  const [unboxedGachas, setUnboxedGachas] = useState<boolean[]>([])
  const [openModal, setOpenModal] = useState(false)

  const { data: drawnGachas } = useQuery<ProductTicket[]>({
    queryKey: ['drawnGachas', id],
    enabled: false,
    initialData: () => {
      return queryClient.getQueryData(['drawnGachas', id])
    },
  })

  const initialize = () => {
    setUnboxedGachas([])
    refetchUserData()
  }

  useEffect(() => {
    return () => {
      initialize()
    }
  }, [])

  useEffect(() => {
    if (drawnGachas) {
      setUnboxedGachas(Array(drawnGachas.length).fill(false))
      setIsPlay(true)
    } else {
      goToHome()
    }
  }, [drawnGachas])

  const skipToPlay = () => {
    setIsPlay(false)
  }

  const handleConfirm = (index: number) => {
    setUnboxedGachas((prev) => {
      const updated = [...prev]
      updated[index] = true
      return updated
    })
  }

  const handleConfirmAll = () => {
    setUnboxedGachas(Array(drawnGachas?.length).fill(true))
  }

  const handleNavigate = () => {
    navigate(`/gacha`)
  }

  const { mutate } = useMutation({
    mutationFn: ({ productId, count }: { productId: string; count: number }) =>
      drawProduct(productId, count),
    onSuccess: (drawTicket) => {
      queryClient.setQueryData(['drawnGachas', id], drawTicket.data)
      navigate(`/product/${id}/${drawCount}/result`)
    },
    onError: (e: AxiosError) => {
      refetchUserData()
      if (
        (
          e?.response?.data as {
            message?: string
          }
        )?.message === 'Not enough point'
      ) {
        setOpenModal(true)
      } else {
        alert('일시적인 오류가 발생했습니다. 다시 시도해주세요.')
      }
    },
  })

  const handleMoreTicket = () => {
    if (!isAuth) {
      navigate('/login')
    } else {
      try {
        if (drawCount != null) {
          mutate({
            productId: id as string,
            count: parseInt(drawCount),
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const allUnboxed = unboxedGachas.every((item) => item)

  if (!drawnGachas || drawnGachas.length === 0) {
    return (
      <Modal
        title="일시적인 오류가 발생했습니다"
        desc="이전 페이지로 돌아가 다시 시도해주세요"
        onConfirm={() => {
          goToHome()
        }}
        confirmText="확인"
      />
    )
  }

  return (
    <>
      {isPlay ? (
        <Playing skipToPlay={skipToPlay} setIsPlay={setIsPlay} />
      ) : (
        <Wrapper>
          <List>
            {drawnGachas?.map((drawnGacha, idx) => (
              <GachaResultItem
                key={`gacha${idx}`}
                isUnboxed={unboxedGachas[idx]}
                drawnGacha={drawnGacha}
                index={idx}
                handleConfirm={handleConfirm}
                allUnboxed={allUnboxed}
              />
            ))}
          </List>

          <Actions>
            {allUnboxed ? (
              <>
                <Button variant="tertiary" onClick={handleNavigate}>
                  확인
                </Button>
                <Button variant="primary" onClick={handleMoreTicket} width="200%">
                  {drawCount}회 더 뽑기
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={handleConfirmAll}>
                모두 확인
              </Button>
            )}
          </Actions>
        </Wrapper>
      )}

      {openModal && (
        <Modal
          title="포인트가 부족합니다"
          desc="포인트를 충전해 주세요"
          onConfirm={() => navigate('/point')}
          onCancel={() => setOpenModal(false)}
          confirmText="충전"
          cancelText="확인"
        />
      )}
    </>
  )
}

export default GachaResult

const Wrapper = styled.div`
  background-color: var(--white);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 88px 20px;
  flex: 1;
`

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 100%;
  overflow-y: scroll;
`

const Actions = styled.div`
  width: 100%;
  z-index: 3;
  max-width: var(--max-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  bottom: 0;
  position: fixed;
  background-color: var(--white);
`
