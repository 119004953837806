import styled from '@emotion/styled'
import { useState } from 'react'

import Button from '@/components/common/Button'
import Step1 from '@/components/gacha/FortuneSelect'
import Step2 from '@/components/gacha/GachaOpenAnimation'

interface PlayingProps {
  skipToPlay: () => void
  setIsPlay: (value: boolean) => void
}

const Playing = ({ skipToPlay, setIsPlay }: PlayingProps) => {
  const [step, setStep] = useState<number>(1)
  const [luckyOption, setLuckyOption] = useState<string>('')

  return (
    <Container>
      <BtnWrap>
        <Button variant="textSecondary" onClick={skipToPlay}>
          SKIP
        </Button>
      </BtnWrap>

      <StepWrap>
        {step === 1 ? (
          <Step1 setStep={setStep} luckyOption={luckyOption} setLuckyOption={setLuckyOption} />
        ) : (
          <Step2 setIsPlay={setIsPlay} />
        )}
      </StepWrap>
    </Container>
  )
}

export default Playing

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

const BtnWrap = styled.div`
  font-weight: 500;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
`

const StepWrap = styled.div`
  overflow: hidden;
`
