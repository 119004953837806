export const ACCOUNT_STATUS = {
  normal: '일반',
}

export const PRODUCT_STATUS = {
  normal: '정상',
}

export const GACHA_STATUS: Record<string, { text: string; color: string }> = {
  normal: { text: '발급 요청 전', color: 'var(--gray600)' },
  request: { text: '발급 요청', color: 'var(--negative)' },
  used: { text: '발급 완료', color: 'var(--positive)' },
  refund: { text: '포인트 환급', color: 'var(--cautionary)' },
}

export const REQUESTED_GACHA_STATUS = {
  request: { text: '발급 요청', color: 'var(--negative)' },
  send: { text: '발급 완료', color: 'var(--positive)' },
}

export const PURCHASE_STATUS = {
  0: '결제 전',
  1: '결제 완료',
  2: '결제 실패',
  3: '환불',
}

export const ORDER_STATUS = {
  success: { text: '결제 성공', color: 'var(--positive)' },
  ready: { text: '결제 준비', color: 'var(--black)' },
}
