import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useNavigate } from 'react-router-dom'

import useAuthCheck from '@/hooks/useAuthCheck'
import { userState } from '@/store/auth'
import { MODAL_KEYS } from '@/constants/modal'
import { SALE_OPTIONS } from '@/constants'
import { formatNum } from '@/utils/format'

import Button from '@/components/common/Button'
import TextField from '@/components/common/TextField'
import TossPayModule from '@/pages/order/TossPayModule'
import Modal from '@/components/common/Modal'
import OrderHistory from '@/components/point/OrderHistory'
import Tab from '@/components/common/Tab'
import useOrder from '@/hooks/useOrder'

const MyPoint = () => {
  const navigate = useNavigate()
  const user = useRecoilValue(userState)
  const isAuth = useAuthCheck()
  const { postOrderV2Mutation } = useOrder()

  const [point, setPoint] = useState<number>(SALE_OPTIONS[0]?.value || 0)
  const [openModal, setOpenModal] = useState<string | null>(null)
  const [activeTab, setActiveTab] = useState<'charge' | 'history'>('charge')

  useEffect(() => {
    if (!isAuth || !user) {
      navigate('/login')
    }
  }, [navigate, isAuth, user])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (user) {
      if (!user.phone) {
        setOpenModal(MODAL_KEYS.PHONE_NUMBER_REQUIRED)
      } else {
        // 기존 토스 페이먼트 코드
        // setOpenModal(MODAL_KEYS.PAY)

        // v2 코드
        postOrderV2Mutation.mutate({ price: point, account: user.accountId })

        // v1 코드
        // postOrderV1Mutation.mutate({ price: point, account: user.accountId })
      }
    }
  }

  if (!user) {
    return null
  }

  return (
    <Container>
      <Header>
        <Tab
          initialTab="charge"
          tabNames={[
            ['charge', '포인트 충전'],
            ['history', '충전 내역'],
          ]}
          onTabChange={(tab) => setActiveTab(tab as 'charge' | 'history')}
        />
      </Header>

      <Wrapper>
        {activeTab === 'charge' && (
          <>
            <div>
              <Title>보유</Title>
              <Price>{formatNum(user.point)}P</Price>
            </div>

            <div>
              <Title>
                충전 <Desc>(포인트 충전 후 바로 사용 가능)</Desc>
              </Title>
              <Actions>
                <TextField
                  type="radio"
                  name="pointOptions"
                  options={SALE_OPTIONS}
                  width="100%"
                  onChange={(e) => setPoint(Number(e.target.value))}
                  placeholder="충전 금액 선택"
                  value={point}
                />

                <Button variant="primary" onClick={handleSubmit} disabled={!point}>
                  충전
                </Button>
              </Actions>
            </div>
          </>
        )}
        {activeTab === 'history' && <OrderHistory />}
      </Wrapper>

      {openModal === MODAL_KEYS.PAY && user && point > 0 && (
        <Modal type="pay">
          <TossPayModule
            price={point}
            accountId={user?.accountId}
            onClose={() => setOpenModal(null)}
          />
        </Modal>
      )}

      {openModal === MODAL_KEYS.PHONE_NUMBER_REQUIRED && (
        <Modal
          title="휴대폰 번호를 등록해 주세요"
          desc="티켓 발송을 위해 휴대폰 번호를 등록해 주세요"
          onConfirm={() => {
            setOpenModal(null)
            navigate('/profile')
          }}
          confirmText="확인"
        />
      )}
    </Container>
  )
}

export default MyPoint

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: var(--white);
  width: calc(100% - 32px);
  max-width: calc(var(--max-width) - 32px);
  top: 56px;
  margin: 0 auto;
  gap: 20px;
  box-sizing: border-box;
  white-space: nowrap;
  padding-bottom: 16px;
`

const Title = styled.p`
  font-size: 14px;
  color: var(--gray500);
  margin-bottom: 8px;
`

const Price = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 47px;
  width: 100%;
  flex: 1;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
`

const Desc = styled.span`
  font-size: 12px;
  color: var(--gray500);
`
