import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getTickets } from '@/apis/cms'
import { Ticket } from '@/types'
import { PAGE_SIZE } from '@/constants'
import { formatNum } from '@/utils/format'
import { tablet, desktop } from '@/styles/commonStyles'

import Pagination from '@/components/cms/Pagination'
import TableContainer from '@/components/common/Table'
import Loading from '@/components/common/Loading'
import Card from '@/components/cms/Card'

const TicketsPage = () => {
  const [page, setPage] = useState(0)

  const { data: ticketsData, isLoading } = useQuery({
    queryKey: ['tickets', page],
    queryFn: () => getTickets(page, PAGE_SIZE),
  })

  const tickets = ticketsData?.content || []
  const totalPages = ticketsData?.totalPages || 0

  if (isLoading) return <Loading />

  return (
    <>
      <TableContainer css={desktop}>
        <table>
          <thead>
            <tr>
              <th css={{ width: '5%' }}>ID</th>
              <th css={{ width: '40%' }}>이름</th>
              <th>카테고리</th>
              <th>등급</th>
              <th>가격</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket: Ticket) => (
              <tr key={ticket.ticketId}>
                <td>{ticket.ticketId}</td>
                <td>{ticket.ticketName}</td>
                <td>{ticket.ticketGroup}</td>
                <td>{ticket.ticketTier}</td>
                <td>{formatNum(ticket.ticketPrice)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      <div css={tablet}>
        {tickets.map((ticket: Ticket) => (
          <Card
            key={ticket.ticketId}
            id={`${ticket.ticketId}`}
            fields={[
              { label: '이름', value: ticket.ticketName },
              { label: '카테고리', value: ticket.ticketGroup },
              { label: '등급', value: ticket.ticketTier },
              { label: '가격', value: `${formatNum(ticket.ticketPrice)} 원` },
            ]}
          />
        ))}
      </div>

      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </>
  )
}

export default TicketsPage
