import { useEffect, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import useAuthCheck from '@/hooks/useAuthCheck'
import useLogout from '@/hooks/useLogout'
import { adminCheck } from '@/apis/cms'

interface AdminCheckProps {
  children: ReactNode
}

const AdminCheck = ({ children }: AdminCheckProps) => {
  const navigate = useNavigate()
  const isAuth = useAuthCheck()
  const logout = useLogout()

  const { data: isAdmin, isLoading } = useQuery({
    queryKey: ['isAdmin'],
    queryFn: () => adminCheck(),
  })

  useEffect(() => {
    if (!isAuth) {
      navigate('/login')
    }
  }, [isAuth, navigate])

  useEffect(() => {
    if (!isLoading && !isAdmin) {
      alert('관리자가 아닙니다')
      logout()
    }
  }, [isAdmin])

  if (!isAuth || !isAdmin || isLoading) {
    return null
  }

  return <>{children}</>
}

export default AdminCheck
