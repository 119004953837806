import styled from '@emotion/styled'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

import BottomNav from '@/components/layout/BottomNav'
import Header from '@/components/layout/Header'

interface LayoutProps {
  showMenu?: boolean
  onlyHeader?: boolean
  hasBack?: boolean
}

const Layout = ({ showMenu = false, onlyHeader = false, hasBack }: LayoutProps) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Container showMenu={showMenu}>
      {showMenu && <Header hasBack={hasBack} />}

      <Content showMenu={showMenu}>
        <Outlet />
      </Content>

      {showMenu && !onlyHeader && <BottomNav />}
    </Container>
  )
}

export default Layout

const Container = styled.div<{ showMenu: boolean }>`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;

  ${({ showMenu }) =>
    showMenu
      ? `padding-top: var(--header-height); padding-bottom: var(--bottom-nav-height);`
      : `height: 100dvh;`}
`

const Content = styled.div<{ showMenu: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: ${({ showMenu }) =>
    showMenu ? `calc(100dvh - (var(--header-height) + var(--bottom-nav-height)))` : '100dvh'};
  padding: ${({ showMenu }) => (showMenu ? '16px' : '')};
`
