import styled from '@emotion/styled'

import { Gacha } from '@/types'
import { GACHA_STATUS } from '@/constants/status'
import { formatDate, formatNum } from '@/utils/format'
import { MODAL_KEYS } from '@/constants/modal'
import { ModalType } from '@/types/modal'

import Button from '@/components/common/Button'

interface MyGachaItemProps {
  ticket: Gacha
  handleGachaAction: (action: 'refund' | 'issue', gachaId: number) => void
  setSelectedGacha: (gacha: Gacha) => void
  setOpenModal: (modal: ModalType) => void
}

const MyGachaItem = ({
  ticket,
  setSelectedGacha,
  setOpenModal,
  handleGachaAction,
}: MyGachaItemProps) => (
  <Item status={ticket.gachaStatus}>
    <Status status={ticket.gachaStatus}>{GACHA_STATUS[ticket.gachaStatus]?.text}</Status>

    <Desc>
      <Image
        src={
          ticket.ticket.ticketImage1
            ? ticket.ticket.ticketImage1
            : `${process.env.REACT_APP_IMG_URL}/logo/logo_white.svg`
        }
        alt="티켓 이미지"
      />

      <Details>
        <div>
          <Date>{formatDate(ticket.createdAt)} 당첨</Date>
          <Title>{ticket?.ticket?.ticketName}</Title>
        </div>

        <div>
          <Price>
            <span>₩</span>
            <p>{formatNum(ticket?.ticket?.ticketPrice)}</p>
          </Price>
        </div>
      </Details>
    </Desc>

    {ticket.gachaStatus === 'normal' && (
      <Actions>
        <Button
          variant="secondary"
          size="small"
          onClick={() => {
            setSelectedGacha(ticket)
            handleGachaAction('issue', ticket.gachaId)
          }}
        >
          발급 요청
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            setSelectedGacha(ticket)
            setOpenModal(MODAL_KEYS.REFUND_GACHA)
          }}
        >
          포인트 전환
        </Button>
      </Actions>
    )}
  </Item>
)

export default MyGachaItem

const Item = styled.div<{ status: string }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  opacity: ${({ status }) => (status === 'refund' ? '0.6' : '1')};
  border-radius: 16px;
  padding: 16px;
  background-color: var(--gray100);
`

const Desc = styled.div`
  display: flex;
  gap: 12px;
`

const Image = styled.img`
  min-width: 80px;
  width: 20%;
  aspect-ratio: 1;
  overflow: hidden;
  object-fit: cover;
  border-radius: 8px;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const Date = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: var(--gray500);
  line-height: 1;
`

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.3;
  margin-top: 4px;
`

const Price = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;

  span {
    color: var(--primary500);
    font-size: 12px;
  }

  p {
    color: var(--primary500);
  }
`

const Status = styled.p<{ status: string }>`
  color: ${({ status }) => GACHA_STATUS[status]?.color};
  font-weight: 500;
  line-height: 1;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`
