import ReactDOM from 'react-dom/client'
import ReactPixel from 'react-facebook-pixel'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RecoilRoot } from 'recoil'
import { ErrorBoundary } from 'react-error-boundary'

import App from '@/App'
import ErrorFallback from '@/pages/ErrorFallBack'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const PIXEL_ID = `${process.env.REACT_APP_PIXEL_ID}`

ReactPixel.init(PIXEL_ID)
ReactPixel.pageView()

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </RecoilRoot>
  </QueryClientProvider>,
)
