import { css } from '@emotion/react'

export const fontJua = css`
  font-family: 'Jua', sans-serif;
`

export const desktop = css`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`

export const tablet = css`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`
