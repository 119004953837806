import styled from '@emotion/styled'

import { fontJua } from '@/styles/commonStyles'

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  ${fontJua}
`

export default Title
