import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

interface GachaOpenAnimationProps {
  setIsPlay: (value: boolean) => void
}

const GachaOpenAnimation = ({ setIsPlay }: GachaOpenAnimationProps) => {
  const [screen, setScreen] = useState<number>(0)

  useEffect(() => {
    const runAnimation = async () => {
      await new Promise((resolve) => setTimeout(() => resolve(setScreen(1)), 1000))
      await new Promise((resolve) => setTimeout(() => resolve(setScreen(2)), 1000))
      setTimeout(() => setIsPlay(false), 2000)
    }

    runAnimation()

    return () => {
      setIsPlay(false)
    }
  }, [setIsPlay])

  return (
    <AnimationWrapper>
      {screen === 0 && (
        <InitialScreen>
          <img src={`${process.env.REACT_APP_IMG_URL}/logo/logo_main.svg`} alt="logo" width={800} />
        </InitialScreen>
      )}

      {screen === 1 && (
        <>
          <TransitionScreen>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/logo/logo_white.svg`}
              alt="logo"
              width={800}
            />
          </TransitionScreen>
          <TransitionBackground />
        </>
      )}

      {screen === 2 && (
        <FinalScreen>
          <CardWrapper>
            <img
              src={`${process.env.REACT_APP_IMG_URL}/games/random-card/card.svg`}
              alt="random_card"
            />
          </CardWrapper>
        </FinalScreen>
      )}
    </AnimationWrapper>
  )
}

export default GachaOpenAnimation

const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100dvh;
  padding: 52px 0;
`

const InitialScreen = styled.div`
  @keyframes rotateAndScale {
    0% {
      transform: rotate(-25deg) scale(0.15);
    }
    4.15% {
      transform: rotate(25deg) scale(0.15);
    }
    8.30% {
      transform: rotate(-25deg) scale(0.15);
    }
    12.45% {
      transform: rotate(25deg) scale(0.15);
    }
    16.60% {
      transform: rotate(-25deg) scale(0.15);
    }
    20.75% {
      transform: rotate(25deg) scale(0.15);
    }
    24.90% {
      transform: rotate(-25deg) scale(0.15);
    }
    29.05% {
      transform: rotate(25deg) scale(0.15);
    }
    33.20% {
      transform: rotate(-25deg) scale(0.15);
    }
    37.35% {
      transform: rotate(25deg) scale(0.15);
    }
    41.50% {
      transform: rotate(-25deg) scale(0.15);
    }
    45.65% {
      transform: rotate(25deg) scale(0.15);
    }
    49.80% {
      transform: rotate(-25deg) scale(0.15);
    }
    53.85% {
      transform: rotate(25deg) scale(0.15);
    }
    56.5% {
      transform: rotate(-25deg) scale(0.15);
    }
    61.5% {
      transform: rotate(-25deg) scale(0.15);
    }
    68.3% {
      transform: rotate(25deg) scale(0.3);
    }
    73.3% {
      transform: rotate(25deg) scale(0.3);
    }
    76% {
      transform: rotate(-25deg) scale(0.45);
    }
    76.9% {
      transform: rotate(-25deg) scale(0.45);
    }
    81.9% {
      transform: rotate(25deg) scale(0.6);
    }
    86.9% {
      transform: rotate(25deg) scale(0.6);
    }
    91% {
      transform: rotate(-25deg) scale(0.75);
    }
    96% {
      transform: rotate(-25deg) scale(0.75);
    }
    100% {
      transform: rotate(25deg) scale(0.85);
    }
  }

  img {
    animation: rotateAndScale 1000ms forwards;
    transform-origin: center;
  }
`

const TransitionScreen = styled.div`
  @keyframes slideInLeft {
    0% {
      position: relative;
      left: 0px;
    }
    20% {
      position: relative;
      left: 0px;
    }
    28% {
      position: relative;
      left: -70px;
    }
    33% {
      position: relative;
      left: -70px;
    }
    38% {
      position: relative;
      left: 135px;
    }
    40% {
      position: relative;
      left: 180px;
    }
    42% {
      position: relative;
      left: 140px;
    }
    44% {
      position: relative;
      left: 144px;
    }
    46% {
      position: relative;
      left: 150px;
    }
    47% {
      position: relative;
      left: 155px;
    }
    54% {
      position: relative;
      left: 155px;
    }
    70% {
      position: relative;
      left: 155px;
    }
    100% {
      position: relative;
      left: -100vw;
    }
  }

  img {
    animation: slideInLeft 1000ms forwards;
    transform: rotate(25deg) scale(0.85);
    transform-origin: center;
    position: relative;
    z-index: 1;
  }
`

const TransitionBackground = styled.div`
  @keyframes SlideBackground {
    0% {
      left: 0px;
    }
    100% {
      left: -110vw;
    }
  }
  position: absolute;
  display: flex;
  width: 110vw;
  height: 100dvh;
  padding: 52px 20px;
  background-color: var(--primary500);
  animation: SlideBackground 1s forwards;
  animation-delay: 1.5s;
  z-index: 0;
`

const FinalScreen = styled.div`
  @keyframes slideInCenter {
    0% {
      perspective: 100px;
      left: -100vw;
    }
    3% {
      perspective: 100px;
      left: -50vw;
    }
    6% {
      perspective: 1000px;
      left: -0vw;
    }
    10% {
      perspective: 1000px;
      left: -0vw;
    }
    30% {
      perspective: 1000px;
      left: -0vw;
    }
    70% {
      perspective: 1000px;
      left: -0vw;
      transform: scale(1.1);
    }
    75% {
      transform: scale(0);
      perspective: 1000px;
      left: -0vw;
    }
    100% {
      transform: scale(0);
      perspective: 1000px;
      left: -0vw;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -100vw;
  perspective: 1000px;
  animation: slideInCenter 2000ms forwards;

  img {
    width: 30%;
    transform: rotate3d(0, 1, 0, 0deg);
  }
`

const CardWrapper = styled.div`
  @keyframes rotateCard {
    0% {
      transform: rotate3d(0, 1, 0, 42deg);
    }
    15% {
      transform: rotate3d(0, 1, 0, 42deg);
    }
    20% {
      transform: rotate3d(0, 1, 0, 360deg);
    }
    25% {
      transform: rotate3d(0, 1, 0, 0deg);
    }
    30% {
      transform: rotate3d(0, 1, 0, 360deg);
    }
    35% {
      transform: rotate3d(0, 1, 0, 0deg);
    }
    40% {
      transform: rotate3d(0, 1, 0, 360deg);
    }
    100% {
      transform: rotate3d(0, 1, 0, 0deg);
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  animation: rotateCard 1200ms forwards;
  transform: rotate3d(0, 1, 0, 360deg);
`
