const hasBatchim = (char: string) => {
  const lastChar = char.charAt(char.length - 1)
  const charCode = lastChar.charCodeAt(0)
  return (charCode - 44032) % 28 !== 0
}

export const required = (fieldName: string) => (value: string) => {
  const particle = hasBatchim(fieldName) ? '을' : '를'
  return value ? undefined : `${fieldName}${particle} 입력해 주세요`
}

export const minLength = (fieldName: string, min: number) => (value: string) => {
  const particle = hasBatchim(fieldName) ? '은' : '는'
  return value.length >= min ? undefined : `${fieldName}${particle} 최소 ${min}자 이상이어야 합니다`
}

export const email = () => (value: string) => {
  return /\S+@\S+\.\S+/.test(value) ? undefined : '올바른 이메일 주소를 입력해 주세요'
}

export const phone = () => (value: string) => {
  return /^[0-9]{11}$/.test(value) ? undefined : `올바른 휴대폰 번호를 입력해 주세요`
}
