import styled from '@emotion/styled'
import ReactPixel from 'react-facebook-pixel'
import { useNavigate } from 'react-router-dom'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import useUserData from '@/hooks/useUserData'
import useAuthCheck from '@/hooks/useAuthCheck'
import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import { getMyGachas, postSendGacha, putRefundGacha } from '@/apis'
import { Gacha } from '@/types'
import { PAGE_SIZE } from '@/constants'
import { MODAL_KEYS } from '@/constants/modal'
import { ModalType } from '@/types/modal'

import Loading from '@/components/common/Loading'
import EmptyGacha from '@/components/gacha/EmptyGacha'
import MyGachaModal from '@/components/gacha/MyGachaModal'
import MyGachaItem from '@/components/gacha/MyGachaItem'

const MyGacha = () => {
  const navigate = useNavigate()
  const { userData: { data: user } = {}, refetchUserData } = useUserData()
  const isAuth = useAuthCheck()
  const [openModal, setOpenModal] = useState<ModalType | null>(null)
  const [selectedGacha, setSelectedGacha] = useState<Gacha>()

  const {
    data: { pages: tickets = [] } = {},
    fetchNextPage,
    isFetchingNextPage,
    refetch: ticketsRefetch,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['tickets'],
    queryFn: ({ pageParam = 0 }) => getMyGachas(pageParam as number, PAGE_SIZE),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageable.pageNumber + 1
      return nextPage < lastPage.totalPages ? nextPage : undefined
    },
    initialPageParam: 0,
  })

  const observerRef = useInfiniteScroll({
    callback: fetchNextPage,
    isFetchingNextPage,
    isLoading,
  })

  const { mutate: refundGachaMutation } = useMutation({
    mutationFn: (gachaId: number) => putRefundGacha(gachaId),
    onSuccess: (data) => {
      if (data.message === 'Success') {
        ticketsRefetch()
        refetchUserData()
        ReactPixel.trackCustom('럭키기프트 포인트 환불', {
          userId: user?.accountId,
          name: user?.name,
          gachaId: selectedGacha && selectedGacha.gachaId,
          value: selectedGacha && selectedGacha?.ticket?.ticketRefundPoint / 2,
        })
        setOpenModal(MODAL_KEYS.SUCCESS_REFUND)
      } else {
        setOpenModal(MODAL_KEYS.ERROR)
      }
    },
    onError: () => {
      setOpenModal(MODAL_KEYS.ERROR)
    },
  })

  const { mutate: sendGachaMutation } = useMutation({
    mutationFn: (gachaId: number) => postSendGacha(gachaId),
    onSuccess: (data) => {
      if (data.message === 'Success') {
        if (selectedGacha?.ticket.ticketStatus === 'auto') {
          ReactPixel.trackCustom('럭키기프트 발급 완료', {
            userId: user?.accountId,
            name: user?.name,
            phone: user?.phone,
            ticketId: selectedGacha?.ticket.ticketId,
            ticketStatus: selectedGacha?.ticket.ticketStatus,
            ticketPrice: selectedGacha?.ticket.ticketPrice,
          })
          setOpenModal(MODAL_KEYS.SUCCESS_AUTO_SEND)
        } else {
          ReactPixel.trackCustom('럭키기프트 발급 요청 완료', {
            userId: user?.accountId,
            name: user?.name,
            phone: user?.phone,
            ticketId: selectedGacha?.ticket.ticketId,
            ticketStatus: selectedGacha?.ticket.ticketStatus,
            ticketPrice: selectedGacha?.ticket.ticketPrice,
          })
          setOpenModal(MODAL_KEYS.SUCCESS_SEND_REQUEST)
        }
        ticketsRefetch()
        setSelectedGacha(undefined)
      } else {
        setOpenModal(MODAL_KEYS.ERROR)
      }
    },
    onError: () => {
      setOpenModal(MODAL_KEYS.ERROR)
    },
  })

  const handleGachaAction = (action: 'refund' | 'issue', gachaId: number) => {
    if (action === 'refund') {
      refundGachaMutation(gachaId)
    } else {
      if (!user.phone) {
        setOpenModal(MODAL_KEYS.PHONE_NUMBER_REQUIRED)
      } else {
        sendGachaMutation(gachaId)
      }
    }
  }

  useEffect(() => {
    if (!isAuth) {
      navigate('/login')
    }
  }, [navigate])

  if (isLoading) return <Loading />

  if (!tickets || tickets[0]?.content?.length === 0) {
    return <EmptyGacha />
  }

  return (
    <Container>
      {tickets
        .flatMap((page) => page.content)
        .map((ticket: Gacha, idx: number) => (
          <MyGachaItem
            key={idx}
            ticket={ticket}
            handleGachaAction={handleGachaAction}
            setSelectedGacha={setSelectedGacha}
            setOpenModal={setOpenModal}
          />
        ))}

      <div ref={observerRef} />

      <MyGachaModal
        openModal={openModal}
        selectedGacha={selectedGacha}
        handleGachaAction={handleGachaAction}
        setOpenModal={setOpenModal}
      />
    </Container>
  )
}

export default MyGacha

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
