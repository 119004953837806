import { useQuery } from '@tanstack/react-query'
import { useSetRecoilState } from 'recoil'
import { useEffect } from 'react'

import { userState } from '@/store/auth'
import { authCheck } from '@/apis/auth'

const useUserData = () => {
  const setUser = useSetRecoilState(userState)

  const {
    data: userData,
    refetch: refetchUserData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => authCheck(),
  })

  useEffect(() => {
    if (userData) {
      setUser(userData.data)
    }
  }, [userData, setUser])

  return { userData, refetchUserData, isLoading, isError }
}

export default useUserData
