import { useNavigate } from 'react-router-dom'

import { Gacha } from '@/types'
import { MODAL_KEYS } from '@/constants/modal'
import { ModalType } from '@/types/modal'
import { formatNum } from '@/utils/format'

import Modal from '@/components/common/Modal'

interface MyGachaModalProps {
  openModal: ModalType | null
  selectedGacha?: Gacha
  handleGachaAction: (action: 'refund' | 'issue', gachaId: number) => void
  setOpenModal: (modal: ModalType | null) => void
}

const MyGachaModal = ({
  openModal,
  selectedGacha,
  handleGachaAction,
  setOpenModal,
}: MyGachaModalProps) => {
  const navigate = useNavigate()

  switch (openModal) {
    case MODAL_KEYS.REFUND_GACHA:
      if (selectedGacha) {
        return (
          <Modal
            title="포인트로 전환하시겠습니까?"
            desc={`${selectedGacha.ticket.ticketName} 상품을\n${formatNum(selectedGacha.ticket.ticketRefundPoint / 2)} 포인트로 전환합니다`}
            onConfirm={() => handleGachaAction('refund', selectedGacha.gachaId)}
            onCancel={() => setOpenModal(null)}
            confirmText="전환"
            cancelText="취소"
          />
        )
      }
      break

    case MODAL_KEYS.SUCCESS_REFUND:
      return (
        <Modal
          title="포인트 전환이 완료되었습니다"
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )

    case MODAL_KEYS.SUCCESS_AUTO_SEND:
      return (
        <Modal
          title="발송이 완료되었습니다"
          desc="카카오톡 선물하기 또는 메세지를 확인해주세요"
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )

    case MODAL_KEYS.SUCCESS_SEND_REQUEST:
      return (
        <Modal
          title="발급 요청이 완료되었습니다"
          desc={
            '발송 요청 시 순차적으로 발송되어\n최대 1일 정도 소요되는 점 참고 부탁드립니다.\n(오후 9시~오전 8시는 야간 발송 제한)\n\n기프티콘은 카카오톡으로 수령 후\n즉시 이용 가능합니다.'
          }
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )

    case MODAL_KEYS.PHONE_NUMBER_REQUIRED:
      return (
        <Modal
          title="휴대폰 번호를 등록해 주세요"
          desc="티켓 발송을 위해 휴대폰 번호를 등록해 주세요"
          onConfirm={() => {
            setOpenModal(null)
            navigate('/profile')
          }}
          confirmText="확인"
        />
      )

    case MODAL_KEYS.ERROR:
      return (
        <Modal
          title="오류가 발생했습니다"
          desc="잠시 후 다시 시도해주세요"
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )

    default:
      return null
  }

  return null
}

export default MyGachaModal
