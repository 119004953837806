import { useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'

import { isAuthState, userState } from '@/store/auth'
import { removeToken } from '@/share/localStorage'

const useLogout = () => {
  const setUser = useSetRecoilState(userState)
  const setIsAuth = useSetRecoilState(isAuthState)
  const navigate = useNavigate()

  const logout = () => {
    setUser(null)
    setIsAuth(false)

    removeToken('accessToken')
    removeToken('refreshToken')

    navigate('/login')
  }

  return logout
}

export default useLogout
