import styled from '@emotion/styled'
import { useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

interface TossPayModuleProps {
  price?: number
  accountId?: number
  onClose?: () => void
}

const OrderProcessing = ({ price = 0, accountId = 0, onClose }: TossPayModuleProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    console.log('OrderProcessing')
    const paymentId = searchParams.get('paymentId') || ''
    const code = searchParams.get('code') || ''
    const message = searchParams.get('message') || ''
    const ordersId = searchParams.get('ordersId') || ''
    console.log('OrderProcessing paymentId', paymentId)
    console.log('OrderProcessing code', code)
    console.log('OrderProcessing message', message)
    console.log('OrderProcessing ordersId', ordersId)

    if (message !== '') {
      navigate(`/payment/v2/result/fail?message=${message}`)
      return
    }

    if (!paymentId) {
      navigate('/payment/v2/result/fail')
      return
    }

    navigate(`/payment/v2/result/success?paymentId=${paymentId}&ordersId=${paymentId}`)
  }, [searchParams, navigate, price])

  // 모바일 결제 시 결제 완료 후 잠깐 보일 화면입니다.
  // UI 구현은 생략하였으니 필요에 따라 구현해주세요.
  return (
    <Container>
      <p>결제 진행 중입니다</p>
      <p>결제가 완료되면 자동으로 이동합니다</p>
    </Container>
  )
}

export default OrderProcessing

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  width: 100%;
  height: 100%;
`
