import styled from '@emotion/styled'

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'textPrimary' | 'textSecondary'
  width?: string
  size?: 'normal' | 'small'
}

const VARIANTS = {
  primary: {
    normal: {
      background: 'var(--primary500)',
      color: 'var(--white)',
      border: 'none',
    },
    hover: {
      background: 'var(--primary600)',
      color: 'var(--white)',
      border: 'none',
    },
    disabled: {
      background: 'var(--gray100)',
      color: 'var(--gray300)',
      border: 'none',
    },
  },
  secondary: {
    normal: {
      background: 'var(--gray200)',
      color: 'var(--gray600)',
      border: 'none',
    },
    hover: {
      background: 'var(--gray300)',
      color: 'var(--gray600)',
      border: 'none',
    },
    disabled: {
      background: 'var(--gray100)',
      color: 'var(--gray300)',
      border: 'none',
    },
  },
  tertiary: {
    normal: {
      background: 'var(--white)',
      color: 'var(--gray500)',
      border: '1px solid var(--gray600)',
    },
    hover: {
      background: 'var(--white)',
      color: 'var(--gray600)',
      border: '1px solid var(--gray600)',
    },
    disabled: {
      background: 'var(--white)',
      color: 'var(--gray300)',
      border: '1px solid var(--gray200)',
    },
  },
  textPrimary: {
    normal: {
      background: 'transparent',
      color: 'var(--primary500)',
      border: 'none',
    },
    hover: {
      background: 'transparent',
      color: 'var(--primary600)',
      border: 'none',
    },
    disabled: {
      background: 'transparent',
      color: 'var(--gray300)',
      border: 'none',
    },
  },
  textSecondary: {
    normal: {
      background: 'transparent',
      color: 'var(--gray500)',
      border: 'none',
    },
    hover: {
      background: 'transparent',
      color: 'var(--gray600)',
      border: 'none',
    },
    disabled: {
      background: 'transparent',
      color: 'var(--gray300)',
      border: 'none',
    },
  },
}

const SIZES = {
  normal: { height: '62px', fontSize: '24px', fontWeight: '500' },
  small: { height: '40px', fontSize: '16px', fontWeight: '500' },
}

const getVariantStyles = (variant: keyof typeof VARIANTS) => {
  const { normal, hover, disabled } = VARIANTS[variant]

  return `
    background-color: ${normal.background};
    color: ${normal.color};
    border: ${normal.border};

    &:hover {
      background-color: ${hover.background};
      color: ${hover.color};
      border: ${hover.border};
    }

    &:disabled {
      background-color: ${disabled.background};
      color: ${disabled.color};
      border: ${disabled.border};
      cursor: normal;
      opacity: 1;
    }
  `
}

const Button = styled.button<ButtonProps>`
  ${({ variant = 'primary', width, size = 'normal' }) => `
    width: ${variant.includes('text') ? 'auto' : width || '100%'};
    height: ${variant.includes('text') ? 'auto' : SIZES[size].height};
    font-size: ${variant.includes('text') ? '14px' : SIZES[size].fontSize};
    font-weight: ${variant.includes('text') ? '400' : SIZES[size].fontWeight};
    border-radius: ${size === 'small' ? '10px' : '16px'};
    ${getVariantStyles(variant)}
  `}
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  transition:
    background-color 0.3s,
    color 0.3s;
`

export default Button
