import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { User } from '@/types'

const { persistAtom: persistUserState } = recoilPersist({
  key: 'userInfo',
  storage: localStorage,
})

const { persistAtom: persistIsAuthState } = recoilPersist({
  key: 'isAuth',
  storage: localStorage,
})

export const userState = atom<User | null>({
  key: 'userState',
  default: null,
  effects_UNSTABLE: [persistUserState],
})

export const isAuthState = atom<boolean>({
  key: 'isAuthState',
  default: false,
  effects_UNSTABLE: [persistIsAuthState],
})
