import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userState } from '@/store/auth'
import moment from 'moment'

import { postOrder } from '@/apis/order'
import PortOne from '@portone/browser-sdk/v2'

const useOrder = (params?: { onError?: (error: any) => void }) => {
  const user = useRecoilValue(userState)
  const navigate = useNavigate()

  // v2 코드
  const postOrderV2Mutation = useMutation({
    mutationFn: ({ price, account }: { price: number; account: number }) =>
      postOrder(price, account),
    onSuccess: async (Order, { price }) => {
      // v2
      const currentDate = moment().format('YYYYMMDDHHmmss')
      const uuid = crypto.randomUUID().substring(0, 5)
      const paymentId = `payment-${currentDate}${uuid}`
      console.log('postOrderV2Mutation paymentId:', paymentId)
      console.log('postOrderV2Mutation Order:', Order)
      console.log('postOrderV2Mutation price:', price)
      console.log('process.env.REACT_APP_PORTONE_STORE_ID', process.env.REACT_APP_PORTONE_STORE_ID)
      console.log('window.location.origin', `${window.location.origin}/payment/processing`)
      console.log(
        'process.env.REACT_APP_PORTONE_WELCOME_CHANNEL_KEY',
        process.env.REACT_APP_PORTONE_WELCOME_CHANNEL_KEY,
      )
      const response = await PortOne.requestPayment({
        storeId: process.env.REACT_APP_PORTONE_STORE_ID || '',
        paymentId: Order.ordersId,
        orderName: Order.ordersName,
        totalAmount: price,
        currency: 'CURRENCY_KRW',
        channelKey: process.env.REACT_APP_PORTONE_WELCOME_CHANNEL_KEY || '',
        payMethod: 'CARD',
        redirectUrl: `${window.location.origin}/payment/processing`,
        customer: {
          fullName: user?.name || '',
          phoneNumber: user?.phone || '',
          email: user?.email || '',
        },
      })
      if (!response) {
        return alert('네트워크 상태를 확인해주세요')
      }
      console.log('response', response)

      if (response.code != null) {
        // 오류 발생
        navigate(`/payment/v2/result/fail?message=${response.message}`)
      }

      if (response.paymentId == null) {
        navigate('/payment/v2/result/fail')
      }

      console.log('결제 후 이동')
      navigate(
        `/payment/v2/result/success?paymentId=${Order.ordersId}&ordersId=${Order.ordersId}&amount=${price}`,
      )
    },
    onError: (error) => {
      console.error(error)
    },
  })

  // v1 코드
  // 리소스가 부족하여 아직은 구현하지 않았습니다.
  // 웰컴페이먼츠도 추가로 붙여야 할때 요청주시면 구현하겠습니다.
  const postOrderV1Mutation = useMutation({
    mutationFn: ({ price, account }: { price: number; account: number }) =>
      postOrder(price, account),
    onSuccess: async (Order, { price }) => {
      // 리소스가 부족하여 아직은 구현하지 않았습니다.
      // 웰컴페이먼츠도 추가로 붙여야 할때 요청주시면 구현하겠습니다.
      //   const { IMP } = window
      //   const userCode = 'imp62499270'
      //   IMP.init(userCode) // 고객사 식별 코드를 넣어 모듈을 초기화해주세요.
      //   IMP.request_pay(
      //     {
      //       pg: 'tosspayments', // 반드시 "tosspayments"임을 명시해주세요.
      //       merchant_uid: 'order_id_test_20240909_0001',
      //       name: '나이키 와플 트레이너 2 SD',
      //       pay_method: 'card',
      //       escrow: false,
      //       amount: '109000',
      //       tax_free: 3000,
      //       buyer_name: '홍길동',
      //       buyer_email: 'buyer@example.com',
      //       buyer_tel: '02-1670-5176',
      //       buyer_addr: '성수이로 20길 16',
      //       buyer_postcode: '04783',
      //       m_redirect_url: 'https://helloworld.com/payments/result', // 모바일 환경에서 필수 입력
      //       notice_url: 'https://helloworld.com/api/v1/payments/notice',
      //       confirm_url: 'https://helloworld.com/api/v1/payments/confirm',
      //       currency: 'KRW',
      //       locale: 'ko',
      //       // custom_data: { userId: 30930 },
      //       display: { card_quota: [0, 6] },
      //       appCard: false,
      //       useCardPoint: true,
      //       bypass: {
      //         tosspayments: {
      //           useInternationalCardOnly: true, // 영어 결제창 활성화
      //         },
      //       },
      //     },
      //     (response: any) => {
      //       // PC 환경에서 결제 프로세스 완료 후 실행 될 로직
      //     },
      //   )
    },
    onError: (error) => {
      console.error(error)
    },
  })

  return { postOrderV2Mutation }
}

export default useOrder
