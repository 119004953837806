import styled from '@emotion/styled'
import { useState, useEffect } from 'react'

import { BANNER_LIST } from '@/constants'

const EventBanner = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % BANNER_LIST.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return <Img src={BANNER_LIST[currentIndex].imageUrl} alt={BANNER_LIST[currentIndex].title} />
}

export default EventBanner

const Img = styled.img`
  border-radius: 16px;
  width: 100%;
  object-fit: cover;
  transition: opacity 1s ease;
  overflow: hidden;
`
