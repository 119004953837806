import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { getProduct } from '@/apis'
import { Product } from '@/types'
import { getProductProbability } from '@/utils'

import LoadingDots from '@/components/common/Loading'
import TableContainer from '@/components/common/Table'
import Title from '@/components/common/Title'

const Probability = () => {
  const { id } = useParams<{ id: string }>()

  const { data: productData, isLoading } = useQuery<{ data: Product }>({
    queryKey: ['product', id],
    queryFn: () => getProduct(id as string),
  })

  if (isLoading) {
    return <LoadingDots />
  }

  if (!productData || !id || !getProductProbability(parseInt(id))) {
    return null
  }

  const productName = productData.data.productName
  const productProbabilities = getProductProbability(parseInt(id))

  return (
    <>
      <Title css={{ marginBottom: '16px' }}>{productName} 등급별 확률</Title>

      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>등급</th>
              <th>확률</th>
            </tr>
          </thead>

          <tbody>
            {productProbabilities.map(({ tier, percentage }) => (
              <tr key={tier}>
                <td>{tier}</td>
                <td>{percentage}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </>
  )
}

export default Probability
