interface IconProps {
  onClick?: () => void
  disabled?: boolean
}

const MinusIcon = ({ onClick, disabled = false }: IconProps) => {
  const fillColor = disabled ? '#F7F7F7' : '#FFC4BE'
  const strokeColor = disabled ? '#9B9B9B' : '#FF6B6B'

  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={!disabled ? onClick : undefined}
      style={{ cursor: onClick && !disabled ? 'pointer' : 'default' }}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill={fillColor} />
      <path d="M8 19H30" stroke={strokeColor} strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

export default MinusIcon
