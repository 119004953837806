import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getRequestedGacha, issueRequestedGacha } from '@/apis/cms'
import { PAGE_SIZE } from '@/constants'
import { REQUESTED_GACHA_STATUS } from '@/constants/status'
import { formatDate, formatNum } from '@/utils/format'
import { RequestedGacha } from '@/types'
import { MODAL_KEYS } from '@/constants/modal'
import { ModalType } from '@/types/modal'
import { tablet, desktop } from '@/styles/commonStyles'

import Pagination from '@/components/cms/Pagination'
import Button from '@/components/common/Button'
import TableContainer from '@/components/common/Table'
import Modal from '@/components/common/Modal'
import Loading from '@/components/common/Loading'
import Card from '@/components/cms/Card'

const RequestedGachaPage = () => {
  const [page, setPage] = useState(0)
  const [selectedGachaId, setSelectedGachaId] = useState<number | null>(null)
  const [openModal, setOpenModal] = useState<ModalType | null>(null)

  const {
    data: requestedData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['requestedGachas', page],
    queryFn: () => getRequestedGacha(page, PAGE_SIZE),
  })

  const { mutate: issueMutation } = useMutation({
    mutationFn: (id: number) => issueRequestedGacha(id),
    onSuccess: () => {
      refetch()
      setOpenModal(MODAL_KEYS.SUCCESS)
    },
    onError: (error) => {
      console.error(error)
      setOpenModal(MODAL_KEYS.ERROR)
    },
  })

  const requestedGachas = requestedData?.content || []
  const totalPages = requestedData?.totalPages || 0

  const handleIssue = (id: number) => {
    setSelectedGachaId(id)
    setOpenModal(MODAL_KEYS.ISSUE)
  }

  const confirmIssue = () => {
    if (selectedGachaId !== null) {
      issueMutation(selectedGachaId)
    }
  }

  if (isLoading) return <Loading />

  return (
    <>
      <TableContainer css={desktop}>
        <table>
          <thead>
            <tr>
              <th css={{ width: '5%' }}>ID</th>
              <th>박스 가격</th>
              <th css={{ width: '40%' }}>티켓</th>
              <th>티켓 가격</th>
              <th>유저 ID</th>
              <th>유저 이름</th>
              <th>유저 핸드폰</th>
              <th>상태</th>
              <th>요청일</th>
            </tr>
          </thead>
          <tbody>
            {requestedGachas.map((requestedGacha: RequestedGacha) => (
              <tr key={requestedGacha.requestGachaId}>
                <td>{requestedGacha.requestGachaId}</td>
                <td>{formatNum(requestedGacha.gacha.gachaPrice)}</td>
                <td>{requestedGacha.gacha.ticket.ticketName}</td>
                <td>{formatNum(requestedGacha.gacha.ticket.ticketPrice)}</td>
                <td>{requestedGacha.account.accountId}</td>
                <td>{requestedGacha.account.name}</td>
                <td>{requestedGacha.account.phone}</td>
                <td
                  css={{
                    color:
                      REQUESTED_GACHA_STATUS[
                        requestedGacha.requestGachaStatus as keyof typeof REQUESTED_GACHA_STATUS
                      ]?.color,
                  }}
                >
                  {requestedGacha.requestGachaStatus === 'request' ? (
                    <Button
                      variant="primary"
                      size="small"
                      width="auto"
                      css={{ padding: '0 10px 0 10px' }}
                      onClick={() => handleIssue(requestedGacha.requestGachaId)}
                    >
                      {
                        REQUESTED_GACHA_STATUS[
                          requestedGacha.requestGachaStatus as keyof typeof REQUESTED_GACHA_STATUS
                        ].text
                      }
                    </Button>
                  ) : (
                    REQUESTED_GACHA_STATUS[
                      requestedGacha.requestGachaStatus as keyof typeof REQUESTED_GACHA_STATUS
                    ].text
                  )}
                </td>

                <td>{formatDate(requestedGacha.createdAt, 'YY-MM-DD HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      <div css={tablet}>
        <p css={{ color: 'var(--gray500)' }}>모바일 버전에서는 발급 확인 버튼이 눌리지 않습니다!</p>
        <p css={{ color: 'var(--gray500)', marginTop: '2px', marginBottom: '8px' }}>
          PC 버전에서 진행해 주세요
        </p>

        {requestedGachas.map((requestedGacha: RequestedGacha) => (
          <Card
            key={requestedGacha.requestGachaId}
            id={`${requestedGacha.requestGachaId}`}
            fields={[
              { label: '박스 가격', value: `${formatNum(requestedGacha.gacha.gachaPrice)} 원` },
              { label: '티켓', value: requestedGacha.gacha.ticket.ticketName },
              {
                label: '티켓 가격',
                value: `${formatNum(requestedGacha.gacha.ticket.ticketPrice)} 원`,
              },
              { label: '유저 ID', value: requestedGacha.account.accountId },
              { label: '유저 이름', value: requestedGacha.account.name },
              { label: '유저 핸드폰', value: requestedGacha.account.phone },
            ]}
            status={
              REQUESTED_GACHA_STATUS[
                requestedGacha.requestGachaStatus as keyof typeof REQUESTED_GACHA_STATUS
              ]?.text
            }
            statusColor={
              REQUESTED_GACHA_STATUS[
                requestedGacha.requestGachaStatus as keyof typeof REQUESTED_GACHA_STATUS
              ]?.color
            }
            dateLabel="요청일"
            dateValue={formatDate(requestedGacha.createdAt, 'YY-MM-DD HH:mm')}
          />
        ))}
      </div>

      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />

      {openModal === MODAL_KEYS.ISSUE && (
        <Modal
          title="발급 확인"
          desc={'해당 티켓을 발급하셨나요?\n확인 버튼을 누르면 리스트에서 발급 완료로 보여집니다'}
          onConfirm={confirmIssue}
          onCancel={() => setOpenModal(null)}
          confirmText="확인"
          cancelText="취소"
        />
      )}

      {openModal === MODAL_KEYS.SUCCESS && (
        <Modal
          title="발급 완료"
          desc="발급 완료 처리되었습니다"
          onConfirm={() => setOpenModal(null)}
          confirmText="확인"
        />
      )}

      {openModal === MODAL_KEYS.ERROR && (
        <Modal title="잘못된 접근입니다" onConfirm={() => setOpenModal(null)} confirmText="확인" />
      )}
    </>
  )
}

export default RequestedGachaPage
