import styled from '@emotion/styled'
import { useState, useEffect } from 'react'

import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import { ProductTicket, Product } from '@/types'
import { formatNum } from '@/utils/format'

interface ProductTicketsProps {
  isLoading: boolean
  productPrice: number
  product: Product
}

const ProductTickets = ({ product, productPrice, isLoading }: ProductTicketsProps) => {
  const [visibleCount, setVisibleCount] = useState(6)
  const [tickets, setTickets] = useState<ProductTicket[]>([])

  const loadMoreTickets = () => {
    setVisibleCount((prevCount) => prevCount + 6)
  }

  const observerRef = useInfiniteScroll({
    callback: loadMoreTickets,
    isFetchingNextPage: false,
    isLoading,
  })

  const getUniqueTickets = (tickets: ProductTicket[]) => {
    return tickets.reduce((acc: ProductTicket[], current: ProductTicket) => {
      const isDuplicate = acc.some(
        (ticket) => ticket?.ticket?.ticketName === current?.ticket?.ticketName,
      )
      if (!isDuplicate) acc.push(current)
      return acc
    }, [])
  }

  const sortTicketsByPrice = (tickets: ProductTicket[]) => {
    return tickets.sort((a, b) => b.ticket?.ticketOriginalPrice - a.ticket?.ticketOriginalPrice)
  }

  useEffect(() => {
    if (product) {
      const uniqueTickets = getUniqueTickets(product.productTickets)
      const sortedTickets = sortTicketsByPrice(uniqueTickets)

      setTickets(sortedTickets)
    }
  }, [product])

  return (
    <>
      <Grid>
        {tickets.slice(0, visibleCount).map((ticket, idx) => (
          <Item key={idx}>
            <Img src={ticket.ticket.ticketImage1} alt={ticket.ticket.ticketName} />

            <Info>
              <Name>{ticket.ticket.ticketName}</Name>
              <Price>
                <OriginalPrice>
                  <span>₩</span>
                  <p>{formatNum(ticket.ticket.ticketOriginalPrice)}</p>
                </OriginalPrice>

                <ProductPrice>
                  <span>₩</span>
                  <p>{formatNum(productPrice)}</p>
                </ProductPrice>
              </Price>
            </Info>
          </Item>
        ))}
      </Grid>

      <div ref={observerRef} />
    </>
  )
}

export default ProductTickets

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  box-sizing: border-box;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
`

const Img = styled.img`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
  border: 1px solid var(--gray200);
`

const Name = styled.p`
  text-align: left;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`

const Info = styled.div`
  width: 100%;
  line-height: 1.2;
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6px 0 6px 0;
  font-weight: 600;
`

const OriginalPrice = styled.div`
  display: flex;
  align-items: center;
  color: var(--gray400);
  text-decoration: line-through;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  span {
    font-size: 12px;
    color: var(--gray400);
  }

  p {
    color: var(--gray400);
  }
`

const ProductPrice = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    color: var(--primary500);
  }

  p {
    font-size: 16px;
    color: var(--primary500);
  }
`
