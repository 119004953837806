import styled from '@emotion/styled'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  unit?: string
  width?: string
}

const Input = ({
  disabled,
  unit,
  type = 'text',
  width,
  value,
  onFocus,
  onBlur,
  onMouseDown,
  onChange,
  ...rest
}: InputProps) => (
  <Label type={type} width={width}>
    <StyledInput
      disabled={disabled}
      type={type}
      value={value}
      hasUnit={!!unit}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseDown={onMouseDown}
      onChange={onChange}
      {...rest}
    />

    {unit && <Unit>{unit}</Unit>}
  </Label>
)

export default Input

const Label = styled.label<{ type?: string; width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  position: relative;
  border: 1px solid var(--gray200);
  border-radius: 16px;

  &:focus-within {
    border: 1px solid var(--gray300);
  }
`

const StyledInput = styled.input<{ width?: string; hasUnit?: boolean }>`
  padding: 0 20px;
  background: transparent;
  border: none;
  width: ${({ hasUnit }) => (hasUnit ? '95%' : '100%')};
  height: 52px;

  ${({ type }) =>
    type === 'number' &&
    `
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield; 
  `}

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--gray300);
  }
`

const Unit = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  position: absolute;
  right: 10px;
`
