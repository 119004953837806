import styled from '@emotion/styled'

interface BottomSheetProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

const BottomSheet = ({ open, onClose, children }: BottomSheetProps) => {
  if (!open) return null

  return (
    <>
      <Backdrop onClick={onClose} />
      <StyledBottomSheet>{children}</StyledBottomSheet>
    </>
  )
}

export default BottomSheet

const StyledBottomSheet = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 20px 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  z-index: 99;
`

const Backdrop = styled.div`
  width: var(--max-width);
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 98;
`
