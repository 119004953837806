import { useState } from 'react'

type ValidationRule = (value: string) => string | undefined

interface ValidationSchema {
  [key: string]: ValidationRule[]
}

interface ValidationErrors {
  [key: string]: string
}

const useValidation = (schema: ValidationSchema) => {
  const [error, setError] = useState<ValidationErrors>({})

  const validate = (values: { [key: string]: any }): boolean => {
    let newError: ValidationErrors = {}

    for (let key in schema) {
      for (let rule of schema[key]) {
        const error = rule(values[key])
        if (error) {
          newError[key] = error
          break
        }
      }
      if (Object.keys(newError).length > 0) break
    }

    setError(newError)
    return Object.keys(newError).length === 0
  }

  return {
    error,
    validate,
  }
}

export default useValidation
