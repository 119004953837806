import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const LoadingDots = () => (
  <LoadingContainer>
    <Dot delay={0} />
    <Dot delay={0.2} />
    <Dot delay={0.4} />
  </LoadingContainer>
)

export default LoadingDots

const bounce = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const Dot = styled.div<{ delay: number }>`
  width: 10px;
  height: 10px;
  background-color: var(--primary500);
  border-radius: 50%;
  margin: 0 5px;
  animation: ${bounce} 1.4s infinite;
  animation-delay: ${({ delay }) => delay}s;
`
