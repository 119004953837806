import { useState, ReactNode } from 'react'
import ReactDOM from 'react-dom'

const useOverlay = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactNode | null>(null)

  const open = (renderContent: ReactNode) => {
    setContent(renderContent)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
    setTimeout(() => {
      setContent(null)
    }, 300)
  }

  const updateOverlay = (newContent: ReactNode) => {
    setContent(newContent)
  }

  const OverlayComponent = () =>
    isOpen && content ? ReactDOM.createPortal(content, document.body) : null

  return {
    open,
    close,
    updateOverlay,
    OverlayComponent,
  }
}

export default useOverlay
