import styled from '@emotion/styled'

interface CardProps {
  id: string
  status?: string
  statusColor?: string
  dateLabel?: string
  dateValue?: string
  fields: { label: string; value: string | number | null }[]
}

const Card = ({ id, fields, status, statusColor, dateLabel, dateValue }: CardProps) => (
  <Wrapper>
    <Header>
      <Id>{id}</Id>
      {status && <Status statusColor={statusColor}>{status}</Status>}
    </Header>

    {fields.map((field, index) => (
      <Item key={index}>
        {field.label}: <strong>{field.value}</strong>
      </Item>
    ))}

    {dateLabel && dateValue && (
      <Date>
        {dateLabel}: {dateValue}
      </Date>
    )}
  </Wrapper>
)

export default Card

const Wrapper = styled.div`
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--white);
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const Id = styled.p`
  font-weight: 600;
`

const Status = styled.div<{ statusColor?: string }>`
  color: ${({ statusColor }) => statusColor || ''};
  font-weight: 600;
`

const Item = styled.div`
  margin-bottom: 8px;
`

const Date = styled.div`
  font-size: 12px;
  color: var(--gray500);
`
