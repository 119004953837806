import { BrowserRouter, Route, Routes } from 'react-router-dom'

import GlobalStyles from '@/styles/GlobalStyles'

import Layout from '@/components/layout/Layout'
import Main from '@/pages/Main'
import MyProfile from '@/pages/auth/MyProfile'
import MyGacha from '@/pages/gacha/MyGacha'
import MyPoint from '@/pages/MyPoint'
import SocialLogin from '@/pages/auth/SocialLogin'
import KakaoCallback from '@/pages/auth/KakaoCallback'
import NaverCallback from '@/pages/auth/NaverCallback'
import Product from '@/pages/product/ProductDetail'
import GachaResult from '@/pages/gacha/GachaResult'
import OrderResult from '@/pages/order/OrderResult'
import Test from '@/pages/order/Test'
import Terms from '@/pages/service/Terms'
import Privacy from '@/pages/service/Privacy'
import Probability from '@/pages/product/Probability'

import CmsLayout from '@/components/cms/Layout'
import Users from '@/pages/cms/Users'
import Products from '@/pages/cms/Products'
import Tickets from '@/pages/cms/Tickets'
import Gachas from '@/pages/cms/Gachas'
import Orders from '@/pages/cms/Orders'
import RequestedGacha from '@/pages/cms/RequestedGacha'
import AdminCheck from '@/components/cms/AdminCheck'
import NotFound from '@/pages/NotFound'
import OrderResultV2 from './pages/order/OrderResultV2'
import OrderProcessing from './pages/order/OrderProcessing'

const App = () => (
  <BrowserRouter>
    <GlobalStyles />

    <Routes>
      <Route path="/" element={<Layout showMenu />}>
        <Route index element={<Main />} />
        <Route path="gacha" element={<MyGacha />} />
        <Route path="point" element={<MyPoint />} />
        <Route path="profile" element={<MyProfile />} />
        <Route path="payment/result/:status" element={<OrderResult />} />
        <Route path="payment/processing" element={<OrderProcessing />} />
        <Route path="payment/v2/result/:status" element={<OrderResultV2 />} />
        <Route path="service/terms" element={<Terms />} />
        <Route path="service/privacy" element={<Privacy />} />
        <Route path="test" element={<Test />} />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route path="login" element={<SocialLogin />} />
        <Route path="login/auth/kakao/callback" element={<KakaoCallback />} />
        <Route path="login/auth/naver/callback" element={<NaverCallback />} />
        <Route path="product/:id/:drawCount/result" element={<GachaResult />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="/" element={<Layout onlyHeader showMenu hasBack />}>
        <Route path="product/:id" element={<Product />} />
        <Route path="product/:id/probability" element={<Probability />} />
      </Route>

      <Route
        path="/cms"
        element={
          <AdminCheck>
            <CmsLayout />
          </AdminCheck>
        }
      >
        <Route index element={<Users />} />
        <Route path="products" element={<Products />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="gachas" element={<Gachas />} />
        <Route path="requested" element={<RequestedGacha />} />
        <Route path="orders" element={<Orders />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default App
