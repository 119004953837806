import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useParams, useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { postOrderSuccessV2 } from '@/apis/order'
import { MODAL_KEYS } from '@/constants/modal'
import { useGoToHome } from '@/hooks/useGoToHome'
import useUserData from '@/hooks/useUserData'
import { postConversionAPI } from '@/share/fpixel'
import { userState } from '@/store/auth'
import { formatNum } from '@/utils/format'

import Button from '@/components/common/Button'
import Loading from '@/components/common/Loading'
import Modal from '@/components/common/Modal'

const OrderResultV2 = () => {
  const goToHome = useGoToHome()
  const { status = 'fail' } = useParams<{ status: string }>()

  const { refetchUserData } = useUserData()
  const [searchParams] = useSearchParams('paymentKey')
  const user = useRecoilValue(userState)
  const ordersId = searchParams.get('ordersId') || ''
  const message = searchParams.get('message') || ''
  const paymentId = searchParams.get('paymentId') || ''
  const [openModal, setOpenModal] = useState<string | null>(null)

  const { mutate, isPending, data } = useMutation({
    mutationFn: ({ ordersId }: { ordersId: string; paymentId: string }) =>
      postOrderSuccessV2(ordersId, paymentId),
    onSuccess: (data) => {
      if (data.totalAmount) {
        ReactPixel.trackCustom('럭키기프트 결제 완료', {
          userId: user?.accountId,
          name: user?.name,
          currency: 'KRW',
          value: data.totalAmount,
        })
        postConversionAPI(
          'Purchase',
          {
            value: data.totalAmount,
            content_name: '포인트구매',
          },
          user,
        )
      }
      refetchUserData()
    },
    onError: (error) => {
      setOpenModal(MODAL_KEYS.ERROR)
    },
  })

  useEffect(() => {
    if (status === 'success') {
      // ReactPixel.trackCustom('럭키기프트 결제 완료', {
      //   userId: user?.accountId,
      //   name: user?.name,
      //   currency: 'KRW',
      //   value: amount,
      // })
      // postConversionAPI(
      //   'Purchase',
      //   {
      //     value: amount,
      //     content_name: '포인트구매',
      //   },
      //   user,
      // )
      console.log('OrderResultV2 ordersId: ', ordersId)
      // console.log('OrderResultV2 amount: ', amount)
      console.log('OrderResultV2 paymentId: ', paymentId)
      mutate({
        ordersId,
        paymentId,
      })
    }
  }, [ordersId])

  if (status === 'success' && (isPending || !data)) return <Loading />

  return (
    <Container status={status}>
      {status === 'success' && (
        <>
          <p>결제 완료되었습니다</p>

          <div>
            <div>
              <span>선택 상품</span>
              <span>{data.ordersName}</span>
            </div>
            <div>
              <span>결제 금액</span>
              <span>{formatNum(data.totalAmount)}원</span>
            </div>
          </div>

          <Button onClick={() => goToHome()}>티켓 뽑으러 가기</Button>
        </>
      )}

      {status === 'fail' && (
        <>
          <p>결제 실패하였습니다</p>

          <div>
            {message && <p>{message}</p>}
            <span>
              해당 오류가 지속적으로 발생하는 경우
              <br />
              아래 메일로 문의해주세요
            </span>
            <br />
            <span>luckygiftcs@lucky-gift.co.kr</span>
          </div>

          <Button onClick={() => goToHome()}>돌아가기</Button>
        </>
      )}

      {openModal === MODAL_KEYS.ERROR && (
        <Modal
          title="잘못된 접근입니다"
          onConfirm={() => {
            setOpenModal(null)
            goToHome()
          }}
          confirmText="확인"
        />
      )}
    </Container>
  )
}

export default OrderResultV2

const Container = styled.div<{ status: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  p {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
  }

  > div {
    background-color: var(--gray100);
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    ${({ status }) => status === 'fail' && 'font-size: 14px;'}
    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`
