import styled from '@emotion/styled'
import { Outlet } from 'react-router-dom'
import { useState } from 'react'

import Header from '@/components/cms/Header'
import SideBar from '@/components/cms/SideBar'

const Layout = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <Container>
      <Header isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />

      <ContentWrapper>
        <SideBar isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />

        <Content>
          <Outlet />
        </Content>
      </ContentWrapper>
    </Container>
  )
}

export default Layout

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100dvh - 70px);
  background-color: var(--gray100);
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`
