export const MODAL_KEYS = {
  REFUND_GACHA: 'refundGacha',
  SUCCESS_REFUND: 'successRefund',
  SUCCESS_AUTO_SEND: 'successAutoSend',
  SUCCESS_SEND_REQUEST: 'successSendRequest',
  PHONE_NUMBER_REQUIRED: 'phoneNumberRequired',
  LACK_OF_POINT: 'lackOfPoint',
  ISSUE: 'issue',
  PAY: 'pay',
  ERROR: 'error',
  SUCCESS: 'success',
} as const
