import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { formatNum } from '@/utils/format'
import { getProductColor } from '@/utils'

interface Product {
  productId: number
  productName: string
  productImage1: string
  productPrice: number
}

interface ProductsProps {
  products: Product[]
}

const Products = ({ products }: ProductsProps) => {
  const navigate = useNavigate()

  return (
    <Grid>
      {products.map((product, idx) => (
        <Item onClick={() => navigate(`/product/${product.productId}`)} key={idx}>
          <Img
            src={product.productImage1 || `${process.env.REACT_APP_IMG_URL}/logo/logo_white.svg`}
            alt={product.productName}
          />

          <Info>
            <Name productId={product.productId}>{product.productName}</Name>

            <Price>
              <span>₩</span>
              <p>{formatNum(product.productPrice)}</p>
            </Price>
          </Info>
        </Item>
      ))}
    </Grid>
  )
}

export default Products

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

const Img = styled.img`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
  border: 1px solid var(--gray200);
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.2;
`

const Name = styled.p<{ productId: number }>`
  font-size: 14px;
  background-color: ${({ productId }) => getProductColor(productId)};
  color: var(--white);
  padding: 4px 12px;
  border-radius: 50px;
  width: fit-content;
  font-weight: 500;
`

const Price = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  font-weight: 600;

  span {
    font-size: 16px;
  }

  p {
    font-size: 18px;
  }
`
