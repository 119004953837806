import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getOrders } from '@/apis/cms'
import { PAGE_SIZE } from '@/constants'
import { ORDER_STATUS } from '@/constants/status'
import { formatDate, formatNum } from '@/utils/format'
import { Order } from '@/types'
import { tablet, desktop } from '@/styles/commonStyles'

import Pagination from '@/components/cms/Pagination'
import TableContainer from '@/components/common/Table'
import Loading from '@/components/common/Loading'
import Card from '@/components/cms/Card'

const OrdersPage = () => {
  const [page, setPage] = useState(0)

  const { data: ordersData, isLoading } = useQuery({
    queryKey: ['orders', page],
    queryFn: () => getOrders(page, PAGE_SIZE),
  })

  const orders = ordersData?.content || []
  const totalPages = ordersData?.totalPages || 0

  if (isLoading) return <Loading />

  return (
    <>
      <TableContainer css={desktop}>
        <table>
          <thead>
            <tr>
              <th css={{ width: '5%' }}>ID</th>
              <th>결제 번호</th>
              <th>결제 상품</th>
              <th>결제 금액</th>
              <th>상태</th>
              <th>유저 ID</th>
              <th>유저</th>
              <th>결제일</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order: Order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.ordersId}</td>
                <td>{order.ordersName}</td>
                <td>{formatNum(order.ordersPrice)}</td>
                <td
                  css={{
                    color: ORDER_STATUS[order.ordersStatus as keyof typeof ORDER_STATUS]?.color,
                  }}
                >
                  {ORDER_STATUS[order.ordersStatus as keyof typeof ORDER_STATUS].text}
                </td>
                <td>{order.account.accountId}</td>
                <td>{order.account.name}</td>
                <td>{formatDate(order.createdAt, 'YY-MM-DD HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      <div css={tablet}>
        {orders.map((order: Order) => (
          <Card
            key={order.id}
            id={`${order.ordersId}`}
            fields={[
              { label: '결제 상품', value: order.ordersName },
              { label: '결제 금액', value: `${formatNum(order.ordersPrice)} 원` },
              { label: '주문자', value: order.account.name },
            ]}
            status={ORDER_STATUS[order.ordersStatus as keyof typeof ORDER_STATUS].text}
            statusColor={ORDER_STATUS[order.ordersStatus as keyof typeof ORDER_STATUS]?.color}
            dateLabel="결제일"
            dateValue={formatDate(order.createdAt, 'YY-MM-DD HH:mm')}
          />
        ))}
      </div>

      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </>
  )
}

export default OrdersPage
