import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'

import { BOTTOM_NAV_ITEMS } from '@/constants'

const BottomNav = () => {
  const { pathname } = useLocation()

  return (
    <Container>
      <BottomItemWrap>
        {BOTTOM_NAV_ITEMS?.map((item, idx) => (
          <li key={idx}>
            <NavLink
              href={item.pathname}
              className={`${pathname === item.pathname ? 'active' : ''}`}
            >
              <img
                src={`${pathname === item.pathname ? item.icon_active : item.icon}`}
                alt={item.alt}
                width={28}
              />
              {item.title}
            </NavLink>
          </li>
        ))}
      </BottomItemWrap>
    </Container>
  )
}

export default BottomNav

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  background-color: var(--white);
  bottom: 0;
  position: fixed;
  padding: 10px 0;
  box-shadow: 0 -1px 1px -1px rgba(0, 0, 0, 0.2);
`

const BottomItemWrap = styled.ul`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  font-size: 12px;
  padding: 8px 20px;
`

const NavLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  color: var(--gray500);
  font-size: 14px;

  img {
    margin-bottom: 4px;
  }

  &.active {
    color: var(--primary500);
  }
`
