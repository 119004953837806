import { useGoToHome } from '@/hooks/useGoToHome'

import Button from '@/components/common/Button'

const OrderFail = () => {
  const goToHome = useGoToHome()

  return (
    <>
      <p>결제 실패하였습니다</p>

      <div>
        <span>
          해당 오류가 지속적으로 발생하는 경우
          <br />
          아래 메일로 문의해주세요
        </span>
        <br />
        <span>luckygiftcs@lucky-gift.co.kr</span>
      </div>

      <Button onClick={() => goToHome()}>돌아가기</Button>
    </>
  )
}

export default OrderFail
