import styled from '@emotion/styled'

const TableContainer = styled.div`
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--gray200);
  overflow: hidden;
  background-color: var(--white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 28px;

  table {
    width: 100%;
    table-layout: fixed;
  }

  thead {
    font-weight: 500;
    border-bottom: 1px solid var(--gray200);
    text-align: left;

    th {
      padding: 14px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  tbody {
    td {
      padding: 16px 0;
      border-bottom: 1px solid var(--gray200);

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export default TableContainer
