import styled from '@emotion/styled'

const SocialLogin = () => (
  <Wrapper>
    <img src={`${process.env.REACT_APP_IMG_URL}/logo/logo_splash.svg`} alt="logo" width={220} />

    <BtnGroup>
      <BtnItem>
        <LoginBtn
          type="kakao"
          href={`https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`}
        >
          <img src={`${process.env.REACT_APP_IMG_URL}/icons/ic_kakao.png`} alt="kakao" width={36} />
          <span>카카오 로그인</span>
        </LoginBtn>
      </BtnItem>

      <BtnItem>
        <LoginBtn
          type="naver"
          href={`https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URL}`}
        >
          <img src={`${process.env.REACT_APP_IMG_URL}/icons/ic_naver.png`} alt="naver" width={36} />
          <span>네이버 로그인</span>
        </LoginBtn>
      </BtnItem>
    </BtnGroup>
  </Wrapper>
)

export default SocialLogin

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100dvh;
  padding: 204px 16px 16px 16px;
  box-sizing: border-box;
`

const BtnGroup = styled.ul`
  display: flex;
  position: absolute;
  bottom: 16px;
  max-width: calc(var(--max-width) - 32px);
  width: calc(100% - 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

const BtnItem = styled.li`
  width: 100%;
`

const LoginBtn = styled.a<{ type: 'kakao' | 'naver' }>`
  background-color: ${({ type }) => (type === 'kakao' ? '#f7e600' : '#03c75a')};
  color: ${({ type }) => (type === 'kakao' ? 'initial' : 'white')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  height: 60px;

  span {
    color: ${({ type }) => (type === 'kakao' ? 'initial' : 'white')};
    font-size: 20px;
    font-weight: 500;
  }
`
