import React, { useEffect } from 'react'
import axios from 'axios'

const Test: React.FC = () => {
  useEffect(() => {
    const fetchPaymentProcessing = async () => {
      const paymentId = 'payment-202409141203125a12f'
      const transactionType = 'PAYMENT'
      const txId = '0191ee7c-0d79-9a08-9470-9d2caf96b44f'
      const url = `https://dev.lucky-gift.co.kr/payment/processing?paymentId=${paymentId}&transactionType=${transactionType}&txId=${txId}`

      console.log('Starting GET request to:', url)

      try {
        console.log('Sending request...')
        const response = await axios.get(url, {
          headers: {
            'Access-Control-Allow-Origin': '*', // 모든 도메인을 허용
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS', // 허용된 메소드
            'Access-Control-Allow-Headers': 'Content-Type, Authorization', // 허용된 헤더
          },
        })

        console.log('Request successful:', response.data)
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.log('Request failed with status code:', error.response.status)
            console.log('Response data:', error.response.data)
          } else if (error.request) {
            console.log(
              'No response received. Possible CORS issue or network error:',
              error.request,
            )
          }
        } else {
          console.log('Error setting up the request:', (error as Error).message)
        }
      } finally {
        console.log('Request finished.')
      }
    }

    fetchPaymentProcessing()
  }, [])

  return (
    <div>
      <h1>Payment Processing</h1>
      <p>Check the console for request logs.</p>
    </div>
  )
}

export default Test
