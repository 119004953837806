import { privateApi } from '@/share/config'

export const getUsers = async (offset: number, limit: number) => {
  try {
    const res = await privateApi.get('/cms/account', { params: { offset, limit } })
    return res
  } catch (e) {
    throw e
  }
}

export const getUser = async (id: number) => {
  try {
    const res = await privateApi.get(`/cms/account/${id}`)
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const getGachas = async (page: number, size: number) => {
  try {
    const res = await privateApi.get('/cms/gacha', { params: { page, size } })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const getTickets = async (offset: number, limit: number) => {
  try {
    const res = await privateApi.get('/cms/ticket', { params: { offset, limit } })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const getOrders = async (page: number, limit: number) => {
  try {
    const res = await privateApi.get('/cms/order', { params: { page, limit } })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const getRequestedGacha = async (page: number, size: number) => {
  try {
    const res = await privateApi.get('/cms/gacha/request', { params: { page, size } })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const issueRequestedGacha = async (id: number) => {
  try {
    const res = await privateApi.put(`/cms/gacha/request/${id}`)
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const adminCheck = async () => {
  try {
    const res = await privateApi.get('/account/isAdmin')
    return res.data.data
  } catch (e) {
    throw e
  }
}
