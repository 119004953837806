export const PAGE_SIZE = 100

export const SALE_OPTIONS = [
  { label: '₩10,000', value: 10000 },
  { label: '₩30,000', value: 30000 },
  { label: '₩50,000', value: 50000 },
  { label: '₩100,000', value: 100000 },
  { label: '₩200,000', value: 200000 },
  { label: '₩300,000', value: 300000 },
  { label: '₩400,000', value: 400000 },
]

export const PRODUCTS = [
  {
    id: 2,
    color: '#648F2F',
    probabilities: [
      { tier: 'S', percentage: 0.97 },
      { tier: 'A', percentage: 1.99 },
      { tier: 'B', percentage: 3.1 },
      { tier: 'C', percentage: 13.05 },
      { tier: 'D', percentage: 39.51 },
      { tier: 'E', percentage: 26.47 },
      { tier: 'F', percentage: 10.94 },
      { tier: 'G', percentage: 3.96 },
    ],
  },
  {
    id: 3,
    color: '#F29127',
    probabilities: [
      { tier: 'S', percentage: 0.82 },
      { tier: 'A', percentage: 2.23 },
      { tier: 'B', percentage: 3.64 },
      { tier: 'C', percentage: 5.67 },
      { tier: 'D', percentage: 10.1 },
      { tier: 'E', percentage: 43.71 },
      { tier: 'F', percentage: 30.73 },
      { tier: 'G', percentage: 3.1 },
    ],
  },
  {
    id: 4,
    color: '#8AC0FD',
    probabilities: [
      { tier: 'S', percentage: 0.95 },
      { tier: 'A', percentage: 1.73 },
      { tier: 'B', percentage: 3.96 },
      { tier: 'C', percentage: 7.02 },
      { tier: 'D', percentage: 12.52 },
      { tier: 'E', percentage: 39.21 },
      { tier: 'F', percentage: 25.54 },
      { tier: 'G', percentage: 9.06 },
    ],
  },
  {
    id: 5,
    color: '#00BDDB',
    probabilities: [
      { tier: 'S', percentage: 0.54 },
      { tier: 'A', percentage: 1.33 },
      { tier: 'B', percentage: 2.38 },
      { tier: 'C', percentage: 3.63 },
      { tier: 'D', percentage: 11.27 },
      { tier: 'E', percentage: 34.39 },
      { tier: 'F', percentage: 23.15 },
      { tier: 'G', percentage: 23.33 },
    ],
  },
]

export const LUCKY_LIST = [
  {
    url: `${process.env.REACT_APP_IMG_URL}/games/random-card/luck_money.png`,
    alt: '재물운 이미지',
    name: '재물운',
  },
  {
    url: `${process.env.REACT_APP_IMG_URL}/games/random-card/luck_love.png'`,
    alt: '애정운 이미지',
    name: '애정운',
  },
  {
    url: `${process.env.REACT_APP_IMG_URL}/games/random-card/luck_health.png`,
    alt: '건강운 이미지',
    name: '건강운',
  },
]

export const BOTTOM_NAV_ITEMS = [
  {
    pathname: '/',
    icon: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_home.svg`,
    icon_active: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_home_active.svg`,
    title: '홈',
    alt: 'go_to_home_page',
  },
  {
    pathname: '/gacha',
    icon: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_ticket.svg`,
    icon_active: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_ticket_active.svg`,
    title: '당첨 티켓',
    alt: 'go_to_ticket_page',
  },
  {
    pathname: '/point',
    icon: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_point.svg`,
    icon_active: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_point_active.svg`,
    title: '포인트 충전',
    alt: 'go_to_point_page',
  },
  {
    pathname: '/profile',
    icon: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_my.svg`,
    icon_active: `${process.env.REACT_APP_IMG_URL}/icons/ic_menu_my_active.svg`,
    title: '내 프로필',
    alt: 'go_to_profile_page',
  },
]

export const BANNER_LIST = [
  {
    imageUrl: `${process.env.REACT_APP_IMG_URL}/banner/banner1.png`,
    title: '럭키기프트에 어서오세요! 행운은 오늘도 당신을 기다리고 있었답니다.',
  },
  {
    imageUrl: `${process.env.REACT_APP_IMG_URL}/banner/banner2.png`,
    title: '오늘은 내가 당첨자! 클릭 한 번으로! 나의 것으로!',
  },
  {
    imageUrl: `${process.env.REACT_APP_IMG_URL}/banner/banner3.png`,
    title: '당첨, 신청, 지급! 기다림도 짧다! 언제 오니? 더 이상 마냥 기다리지 마세요 :)',
  },
]
