import styled from '@emotion/styled'

interface RadioProps {
  name?: string
  options: { value: number | string; label: string }[]
  value?: string | number
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Radio = ({ name, options, value, disabled, onChange }: RadioProps) => (
  <StyledRadio>
    {options.map((option, idx) => (
      <div key={idx}>
        <Input
          type="radio"
          id={`${option.value}`}
          name={name}
          value={option.value}
          onChange={onChange}
          checked={value === option.value}
          disabled={disabled}
        />

        <Label htmlFor={`${option.value}`}>{option.label}</Label>
      </div>
    ))}
  </StyledRadio>
)

export default Radio

const StyledRadio = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: center;
`

const Input = styled.input`
  display: none;

  &:checked + label {
    border-color: var(--primary500);
    background-color: var(--primary100);
    color: var(--primary500);
  }
`

const Label = styled.label`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid var(--gray200);
  color: var(--gray400);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease,
    color 0.2s ease;
`
