import styled from '@emotion/styled'
import { useInfiniteQuery } from '@tanstack/react-query'

import { formatDate, formatNum } from '@/utils/format'
import { getOrders } from '@/apis'
import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import { PAGE_SIZE } from '@/constants'
import { ORDER_STATUS } from '@/constants/status'

const OrderWrapper = () => {
  const {
    data: { pages: orders = [] } = {},
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['orders'],
    queryFn: ({ pageParam = 0 }) => getOrders(pageParam as number, PAGE_SIZE),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageable.pageNumber + 1
      return nextPage < lastPage.totalPages ? nextPage : undefined
    },
    initialPageParam: 0,
  })

  const observerRef = useInfiniteScroll({ callback: fetchNextPage, isFetchingNextPage, isLoading })

  const orderItems = orders.flatMap((page) => page.content)

  if (isLoading) return null

  return (
    <>
      {orderItems.length > 0 ? (
        <Wrapper>
          {orderItems.map((order, idx) => (
            <Item key={idx}>
              <div>
                <Header>
                  <p>일시</p>
                  <p>상태</p>
                  <p>금액</p>
                </Header>
                <Content>
                  <p>{formatDate(order.createdAt)}</p>
                  <p>{ORDER_STATUS[order.ordersStatus as keyof typeof ORDER_STATUS].text}</p>
                  <p>{formatNum(order.ordersPrice)}원</p>
                </Content>
              </div>
            </Item>
          ))}
        </Wrapper>
      ) : (
        <Empty>
          <NoOrderMgs>충전 내역이 없습니다</NoOrderMgs>
        </Empty>
      )}

      <div ref={observerRef} />
    </>
  )
}

export default OrderWrapper

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 20px;
    width: 100%;
    background-color: var(--gray100);
    border-radius: 16px;
    padding: 24px 28px;
  }

  hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: var(--gray100);
    margin: 16px 0;
  }
`

const Header = styled.div`
  width: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    color: var(--gray500);
    font-size: 14px;
    white-space: nowrap;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 14px;
  }
`

const Empty = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const NoOrderMgs = styled.p`
  text-align: center;
  font-size: 14px;
  color: var(--gray400);
`
