import styled from '@emotion/styled'
import { useState, useRef, useEffect } from 'react'

type TabName = string
type TabItem = [TabName, string]

interface TabProps {
  initialTab: TabName
  tabNames: TabItem[]
  onTabChange?: (tab: TabName) => void
}

const Tab = ({ initialTab, tabNames, onTabChange }: TabProps) => {
  const [activeTab, setActiveTab] = useState<TabName>(initialTab)
  const tabRefs = useRef<Record<TabName, HTMLDivElement | null>>({})

  const [activeStyle, setActiveStyle] = useState<{ width: number; left: number }>({
    width: 0,
    left: 0,
  })

  useEffect(() => {
    const activeTabRef = tabRefs.current[activeTab]

    if (activeTabRef) {
      const { offsetWidth, offsetLeft } = activeTabRef
      setActiveStyle({ width: offsetWidth, left: offsetLeft })
    }
  }, [activeTab])

  const setTabRef = (name: TabName) => (element: HTMLDivElement | null) => {
    tabRefs.current[name] = element
  }

  const handleTabClick = (tab: TabName) => {
    setActiveTab(tab)
    if (onTabChange) {
      onTabChange(tab)
    }
  }

  return (
    <Wrapper>
      <ActiveBackground css={activeStyle} />
      {tabNames.map(([tabName, displayName]) => (
        <Item
          key={tabName}
          ref={setTabRef(tabName)}
          active={activeTab === tabName}
          onClick={() => handleTabClick(tabName)}
        >
          {displayName}
        </Item>
      ))}
    </Wrapper>
  )
}

export default Tab

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--primary500);
  border-radius: 50px;
  border: 3px solid var(--primary500);
  overflow: hidden;
  white-space: nowrap;
  height: 45px;
  box-sizing: border-box;
`

const Item = styled.div<{ active: boolean }>`
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: ${({ active }) => (active ? 'var(--primary500)' : 'var(--white)')};
  flex: 1;
  text-align: center;
  z-index: 1;
`

const ActiveBackground = styled.div`
  position: absolute;
  background-color: var(--white);
  border-radius: 50px;
  height: 100%;
  transition:
    width 0.3s ease,
    left 0.3s ease;
  z-index: 0;
`
