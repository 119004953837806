import styled from '@emotion/styled'

import { useGoToHome } from '@/hooks/useGoToHome'

import Button from '@/components/common/Button'

type ErrorFallbackProps = {
  error?: Error
}

const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const goToHome = useGoToHome()

  return (
    <Container>
      <Title>문제가 발생했습니다</Title>
      <Desc>같은 문제가 계속 발생한다면 이메일로 문의해 주세요</Desc>
      <Button onClick={goToHome}>홈으로 이동하기</Button>
    </Container>
  )
}

export default ErrorFallback

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
`

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
`

const Desc = styled.p`
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 30px;
`
