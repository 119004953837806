import axios from 'axios'

import { refreshAccessToken } from '@/apis/auth'
import { getToken, setToken } from '@/share/localStorage'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const createAxiosInstance = (isPrivate = false) => {
  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    },
  })

  if (isPrivate) {
    instance.interceptors.request.use((config) => {
      const accessToken = getToken('accessToken')
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    })

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if ([-6, -7].includes(error.response.data.code) && !originalRequest._retry) {
          originalRequest._retry = true
          const refreshToken = getToken('refreshToken')

          if (refreshToken) {
            try {
              const newTokenData = await refreshAccessToken({ refreshToken })
              if (newTokenData) {
                const { accessToken } = newTokenData
                setToken('accessToken', accessToken)
                originalRequest.headers.Authorization = `Bearer ${accessToken}`
                return instance(originalRequest)
              }
            } catch (refreshError) {
              console.error(refreshError)
              return Promise.reject(refreshError)
            }
          }
        }
        return Promise.reject(error)
      },
    )
  }

  return instance
}

export const publicApi = createAxiosInstance()
export const privateApi = createAxiosInstance(true)
