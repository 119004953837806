import styled from '@emotion/styled'

interface SelectProps {
  placeholder?: string
  value?: string
  disabled?: boolean
  options?: { value: number | string; label: string }[]
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Select = ({ placeholder, options, value, disabled, onChange }: SelectProps) => (
  <StyledSelect required value={value} disabled={disabled} onChange={onChange}>
    {placeholder && (
      <option value="" disabled selected hidden>
        {placeholder}
      </option>
    )}

    {options?.map((option, idx) => (
      <option value={option.value} key={idx}>
        {option.label}
      </option>
    ))}
  </StyledSelect>
)

export default Select

const StyledSelect = styled.select`
  flex: 1;
  border: 1px solid var(--gray200);
  padding: 0 16px;
  background: transparent;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  appearance: none;
  background: url(${process.env.REACT_APP_IMG_URL}/icons/ic_select_arrow.svg) no-repeat right 8px
    center;

  &:focus {
    outline: none;
  }

  &:required:invalid {
    color: var(--gray500);
  }

  &:disabled {
    color: var(--gray300);
  }
`
