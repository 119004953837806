import { useState } from 'react'
import styled from '@emotion/styled'

const Footer = () => {
  const [showInfo, setShowInfo] = useState(true)

  const handleToggle = () => {
    setShowInfo((prev) => !prev)
  }

  return (
    <Wrap>
      <Info>
        <p>
          문의 <a href="mailto:luckygiftcs@lucky-gift.co.kr">luckygiftcs@lucky-gift.co.kr</a>
        </p>

        <div>
          <a href="/service/terms">이용약관</a>
          <span>|</span>
          <a href="/service/privacy">개인정보처리방침</a>
        </div>

        {/* <ShowInfo onClick={handleToggle}>{showInfo ? '▲ 사업자 정보' : '▼ 사업자 정보'}</ShowInfo> */}
        <ShowInfo onClick={handleToggle}>사업자 정보 ⓒLuckyGift</ShowInfo>
        {/* <ShowInfo>사업자 정보 ⓒLuckyGift</ShowInfo> */}

        {showInfo && (
          <ToggleContent>
            업체명: 월드컨트리 | 대표자 박회영 | 사업자주소 서울특별시 구로구 디지털로26길 90 |
            사업자등록번호 249-70-00554 | 대표전화번호 070-8095-7081
          </ToggleContent>
        )}
      </Info>

      <img src={`${process.env.REACT_APP_IMG_URL}/logo/logo_main.svg`} alt="logo" width={28} />
    </Wrap>
  )
}

export default Footer

const Wrap = styled.footer`
  width: calc(100% + 40px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--gray100);
  padding: 20px 16px;
  line-height: 1;
  position: absolute;
  bottom: -20px;
  left: -20px;
`

const Info = styled.div`
  width: 375px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;

  a,
  p,
  span {
    color: var(--gray500);
  }

  > div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

const ShowInfo = styled.div`
  color: var(--gray500);
  font-size: 10px;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`

const ToggleContent = styled.div`
  font-size: 10px;
  color: var(--gray500);
`
