import styled from '@emotion/styled'

interface GachaResultItemProps {
  isUnboxed: boolean
  drawnGacha: {
    ticket: {
      ticketImage1: string
      ticketName: string
    }
  }
  index: number
  handleConfirm: (index: number) => void
  allUnboxed: boolean
}

const GachaResultItem = ({
  isUnboxed,
  drawnGacha,
  index,
  handleConfirm,
  allUnboxed,
}: GachaResultItemProps) => {
  return (
    <Item isUnboxed={isUnboxed || allUnboxed} onClick={() => handleConfirm(index)}>
      <img src={drawnGacha.ticket.ticketImage1} alt={drawnGacha.ticket.ticketName} width={64} />

      <Title isUnboxed={isUnboxed || allUnboxed}>{drawnGacha.ticket.ticketName}</Title>

      <Cover isUnboxed={isUnboxed || allUnboxed}></Cover>
    </Item>
  )
}

export default GachaResultItem

const Item = styled.li<{ isUnboxed: boolean }>`
  min-height: 100px;
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  gap: 10px;

  img {
    min-width: 100px;
    width: 25%;
    aspect-ratio: 1;
    overflow: hidden;
    object-fit: cover;
    border: 1px solid var(--gray200);
    border-radius: 8px;
  }
`

const Cover = styled.div<{ isUnboxed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  top: 0;
  left: 0;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  overflow: hidden;
  cursor: ${({ isUnboxed }) => (isUnboxed ? 'default' : 'pointer')};
  transition: background-color 0.3s ease;
  animation: ${({ isUnboxed }) => (isUnboxed ? 'default' : 'pointer')};

  &:before {
    content: ${({ isUnboxed }) => (isUnboxed ? '""' : '"당첨 확인"')};
    font-size: ${({ isUnboxed }) => (isUnboxed ? '14px' : '20px')};
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-height: 100px;
    background-color: var(--primary500);
    opacity: ${({ isUnboxed }) => (isUnboxed ? 0 : 1)};
    transition: opacity 0.5s ease;
    z-index: 2;
    color: var(--white);
  }
`

const Title = styled.p<{ isUnboxed: boolean }>`
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.3;
`
