import { PRODUCTS } from '@/constants'

const getProduct = (id: number) => PRODUCTS.find((product) => product.id === id)

export const getProductColor = (id: number) => {
  const product = getProduct(id)
  return product ? product.color : null
}

export const getProductProbability = (id: number) => {
  const product = getProduct(id)
  return product ? product.probabilities : []
}
