import styled from '@emotion/styled'
import { useEffect } from 'react'

import { formatNum } from '@/utils/format'

import Button from '@/components/common/Button'
import Minus from '@/icons/Minus'
import Plus from '@/icons/Plus'

interface ProductPurchaseProps {
  maxDrawCount: number
  drawCount: number
  totalPointsRequired: number
  handleDecrease: () => void
  handleIncrease: () => void
  handleDrawClick: () => void
}

const ProductPurchase = ({
  maxDrawCount,
  drawCount,
  totalPointsRequired,
  handleDecrease,
  handleIncrease,
  handleDrawClick,
}: ProductPurchaseProps) => {
  useEffect(() => {}, [totalPointsRequired, drawCount, maxDrawCount])

  return (
    <Container>
      <Content>
        <HeaderWrap>
          <Heading>몇 장 뽑을까요?</Heading>
          <Subtext>보유 포인트로 최대 {formatNum(maxDrawCount)}장 뽑을 수 있습니다</Subtext>
        </HeaderWrap>

        <Counter>
          <Minus onClick={handleDecrease} disabled={drawCount === 1} />
          <Count>{drawCount}</Count>
          <Plus onClick={handleIncrease} disabled={drawCount >= maxDrawCount} />
        </Counter>
      </Content>

      <Points>
        <p>사용 포인트</p>
        <p>{formatNum(totalPointsRequired)} 포인트</p>
      </Points>

      <Button variant="primary" onClick={handleDrawClick}>
        뽑기
      </Button>
    </Container>
  )
}

export default ProductPurchase

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
`

const Count = styled.span`
  font-size: 24px;
  min-width: 20px;
  text-align: center;
  font-weight: 600;
`

const Points = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  background-color: var(--gray100);
  border-radius: 16px;
  padding: 20px;

  p:first-of-type {
    font-size: 14px;
  }

  p:last-of-type {
    color: var(--primary500);
  }
`

const Heading = styled.p`
  font-size: 18px;
  font-weight: 600;
`

const Subtext = styled.p`
  font-size: 14px;
  color: var(--gray500);
`

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Content = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`
