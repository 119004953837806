import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import Button from '@/components/common/Button'

interface ModalProps {
  title?: string
  desc?: string
  confirmText?: string
  cancelText?: string
  children?: React.ReactNode
  type?: 'normal' | 'pay'
  onConfirm?: () => void
  onCancel?: () => void
}

const Modal = ({
  title,
  desc,
  confirmText = '확인',
  cancelText,
  children,
  type = 'normal',
  onConfirm,
  onCancel,
}: ModalProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    const timer = setTimeout(() => {
      setIsActive(true)
    }, 10)

    return () => {
      clearTimeout(timer)
      document.body.style.overflow = 'auto'
      setIsActive(false)
    }
  }, [])

  return (
    <BackDrop isActive={isActive}>
      <Container isActive={isActive} type={type}>
        {type === 'normal' ? (
          <>
            <TitleWrap>
              <Title>{title}</Title>
              <Desc>{desc}</Desc>
            </TitleWrap>

            {children}

            <ButtonWrap>
              {cancelText && (
                <Button width="100%" variant="secondary" onClick={onCancel}>
                  {cancelText}
                </Button>
              )}
              <Button width="100%" variant="primary" onClick={onConfirm}>
                {confirmText}
              </Button>
            </ButtonWrap>
          </>
        ) : (
          <>{children}</>
        )}
      </Container>
    </BackDrop>
  )
}

export default Modal

const BackDrop = styled.div<{ isActive: boolean }>`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  transition: all 200ms ease-in-out;
`

const Container = styled.div<{ isActive: boolean; type: string }>`
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 90%;
  max-width: ${({ type }) => (type === 'normal' ? '400px' : '600px')};
  max-height: 100dvh;
  overflow: auto;
  transform: ${({ isActive }) => (isActive ? 'scale(1)' : 'scale(0)')};
  border-radius: 20px;
  border: 1px solid var(--gray200);
  padding: ${({ type }) => (type === 'normal' ? '40px' : '0px')};
  transition: transform 200ms ease-in-out;
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  align-items: center;
`

const Title = styled.p`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  white-space: break-spaces;
`

const Desc = styled.p`
  display: flex;
  font-size: 14px;
  white-space: break-spaces;
  text-align: center;
  color: var(--gray500);
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 40px;
`
