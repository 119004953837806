import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

interface HeaderProps {
  hasBack?: boolean
}

const Header = ({ hasBack = false }: HeaderProps) => {
  const navigate = useNavigate()

  return (
    <Container>
      <HeaderWrap>
        {hasBack ? (
          <Back onClick={() => navigate(-1)}>
            <img src={`${process.env.REACT_APP_IMG_URL}/icons/ic_back_arrow.svg`} alt="뒤로가기" />
          </Back>
        ) : (
          <Logo data={`${process.env.REACT_APP_IMG_URL}/logo/logo_vertical_ko.svg`} />
        )}
      </HeaderWrap>
    </Container>
  )
}

export default Header

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: var(--max-width);
  background-color: var(--white);
  z-index: 11;
`

const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px 16px;
  height: var(--header-height);
  position: relative;
`

const Back = styled.a`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: 10px;
`

const Logo = styled.object`
  max-height: 100%;
`
