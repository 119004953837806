import { useEffect, useRef } from 'react'

interface UseInfiniteScrollProps {
  callback: () => void
  isFetchingNextPage: boolean
  isLoading: boolean
}

const useInfiniteScroll = ({ callback, isFetchingNextPage, isLoading }: UseInfiniteScrollProps) => {
  const observerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!isLoading && observerRef.current && !isFetchingNextPage) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            callback()
          }
        },
        {
          threshold: 1.0,
        },
      )

      observer.observe(observerRef.current)

      return () => {
        if (observerRef.current) {
          observer.unobserve(observerRef.current)
        }
      }
    }
  }, [isLoading, callback, isFetchingNextPage])

  return observerRef
}

export default useInfiniteScroll
