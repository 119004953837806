import moment from 'moment'

export const formatNum = (num: number | string): string => {
  if (typeof num === 'number') {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else if (typeof num === 'string' && !isNaN(Number(num))) {
    return Number(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return num
  }
}

export const formatDate = (dateString: string, format?: string): string => {
  const date = moment(dateString)

  if (!date.isValid()) {
    throw new Error('Invalid date format')
  }

  const dateFormat = format || 'YY. MM. DD. HH:mm'

  return date.format(dateFormat)
}
