import ReactPixel from 'react-facebook-pixel'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useSetRecoilState } from 'recoil'

import { authCheck, naverLogin } from '@/apis/auth'
import { setToken } from '@/share/localStorage'
import { isAuthState, userState } from '@/store/auth'
import { useGoToHome } from '@/hooks/useGoToHome'

const NaverCallback = () => {
  const navigate = useNavigate()
  const userInfo = useSetRecoilState(userState)
  const isAuth = useSetRecoilState(isAuthState)
  const goToHome = useGoToHome()
  const [searchParams] = useSearchParams()
  const authorizationCode = searchParams.get('code')
  const state = searchParams.get('state')
  const redirectUrl = process.env.REACT_APP_NAVER_REDIRECT_URL

  const { data: callbackData } = useQuery({
    queryKey: ['auth/naver/callback', authorizationCode, state],
    queryFn: () =>
      naverLogin({
        authorizationCode: authorizationCode as string,
        state: state as string,
        redirectUrl: redirectUrl as string,
      }),
    enabled: !!authorizationCode && !!state && !!redirectUrl,
  })

  const { data: userData } = useQuery({
    queryKey: ['auth/check'],
    queryFn: () => authCheck(),
    enabled: !!callbackData?.data.accessToken,
  })

  useEffect(() => {
    if (callbackData?.success) {
      const { accessToken, refreshToken } = callbackData.data
      ReactPixel.trackCustom('럭키기프트 네이버 로그인')
      setToken('accessToken', accessToken)
      setToken('refreshToken', refreshToken)
    }
  }, [callbackData])

  useEffect(() => {
    if (userData?.success) {
      userInfo(userData.data)
      isAuth(true)
      goToHome()
    }
  }, [userData, userInfo, isAuth, navigate])

  return null
}

export default NaverCallback
