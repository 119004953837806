import { privateApi, publicApi } from '@/share/config'

export const getProducts = async (page: number, size: number) => {
  try {
    const res = await publicApi.get('/product', { params: { page, size } })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const getProduct = async (id: string) => {
  try {
    const res = await publicApi.get(`/product/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const drawProduct = async (productId: string, count: number) => {
  try {
    const res = await privateApi.post(`/gacha/draw/${productId}/${count}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const getMyGachas = async (page: number, size: number) => {
  try {
    const res = await privateApi.get('/gacha/ticket', {
      params: {
        page,
        size,
      },
    })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const putProfile = async (name: string, email: string, phone: string) => {
  try {
    const res = await privateApi.put('/account', { name, email, phone })
    return res.data.data
  } catch (e) {
    throw e
  }
}

export const putRefundGacha = async (gachaId: number) => {
  try {
    const res = await privateApi.put(`/gacha/refund/${gachaId}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const postSendGacha = async (gachaId: number) => {
  try {
    const res = await privateApi.post(`/gacha/${gachaId}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const getOrders = async (page: number, limit: number) => {
  try {
    const res = await privateApi.get('/order', {
      params: {
        page,
        limit,
      },
    })
    return res.data.data
  } catch (e) {
    throw e
  }
}
