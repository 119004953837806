import emotionReset from 'emotion-reset'
import { css, Global } from '@emotion/react'
import { useLocation } from 'react-router-dom'

const globalStyles = (isCms: boolean) => css`
  ${emotionReset}
  :root {
    --black: #32353a;
    --gray600: #6b7079;
    --gray500: #878c95;
    --gray400: #a3a8b0;
    --gray300: #c0c4cc;
    --gray200: #e1e4e9;
    --gray100: #f8f9fb;
    --primary600: #05bb6e;
    --primary500: #13ce66;
    --white: #ffffff;
    --positive: #1ed45a;
    --cautionary: #ffa938;
    --negative: #ff2828;

    --max-width: 480px;
    --header-height: 56px;
    --bottom-nav-height: 76px;
  }

  * {
    font-family: 'Pretendard', sans-serif;
    box-sizing: border-box;
    color: var(--black);
  }

  body {
    font-family: 'Pretendard', sans-serif;
    margin: 0;
    background-color: ${isCms ? 'unset' : 'var(--gray100)'};
    font-size: 14px;
    letter-spacing: -0.2px;
    color: var(--black);
    line-height: 1.3;
  }

  a {
    text-decoration: none;
  }
`

const GlobalStyles = () => {
  const location = useLocation()
  const isCms = location.pathname.startsWith('/cms')

  return <Global styles={globalStyles(isCms)} />
}

export default GlobalStyles
