import styled from '@emotion/styled'

import useLogout from '@/hooks/useLogout'

import Button from '@/components/common/Button'

interface HeaderProps {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (isOpen: boolean) => void
}

const Header = ({ isMobileMenuOpen, setIsMobileMenuOpen }: HeaderProps) => {
  const logout = useLogout()

  const handleLogout = () => {
    logout()
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <Wrapper>
      <LogoWrap>
        <MobileMenuIcon onClick={toggleMobileMenu}>+</MobileMenuIcon>

        <img
          src={`${process.env.REACT_APP_IMG_URL}/logo/logo_vertical_ko.svg`}
          alt="로고"
          width={94}
        />
      </LogoWrap>

      <Button variant="textSecondary" width="auto" onClick={handleLogout}>
        로그아웃
      </Button>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 20px;
`

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const MobileMenuIcon = styled.div`
  display: none;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    background: var(--primary500);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`
