import styled from '@emotion/styled'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { getGachas, getUser } from '@/apis/cms'
import { PAGE_SIZE } from '@/constants'
import { GACHA_STATUS } from '@/constants/status'
import { formatDate, formatNum } from '@/utils/format'
import { Gacha, User } from '@/types'
import { tablet, desktop } from '@/styles/commonStyles'

import TableContainer from '@/components/common/Table'
import Pagination from '@/components/cms/Pagination'
import Loading from '@/components/common/Loading'
import Card from '@/components/cms/Card'
import Button from '@/components/common/Button'

const Gachas = () => {
  const [page, setPage] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)

  const { data: gachasData, isLoading } = useQuery({
    queryKey: ['gachas', page],
    queryFn: () => getGachas(page, PAGE_SIZE),
  })

  const gachas = gachasData?.content || []
  const totalPages = gachasData?.totalPages || 0
  const totalElements = gachasData?.totalElements || 0

  const downloadCSV = async () => {
    setIsDownloading(true)
    try {
      const allGachasData = await getGachas(0, totalElements)
      const allGachas = allGachasData.content

      const headers = [
        'ID',
        '박스',
        '박스 가격',
        '티켓 ID',
        '티켓',
        '티켓 가격',
        '유저 ID',
        '상태',
        '당첨일',
      ]

      const rows = allGachas.map((gacha: Gacha) => [
        gacha.gachaId,
        gacha.productId,
        gacha.gachaPrice,
        gacha.ticket.ticketId,
        gacha.ticket.ticketName.split(',')[0],
        gacha.ticket.ticketPrice,
        gacha.accountId,
        gacha.gachaStatus,
        new Date(gacha.createdAt).toLocaleString(),
      ])

      let csvContent =
        'data:text/csv;charset=utf-8,' + [headers, ...rows].map((e) => e.join(',')).join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'gachas_data.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (e) {
      console.error(e)
    } finally {
      setIsDownloading(false)
    }
  }

  const userData = useQueries({
    queries: gachas.map((gacha: Gacha) => ({
      queryKey: ['user', gacha.accountId],
      queryFn: () => getUser(gacha.accountId),
      enabled: !!gachas,
    })),
  }) as { data: User }[]

  const users = userData.map((query) => query.data)

  if (isLoading) return <Loading />

  return (
    <Container>
      <Button
        onClick={downloadCSV}
        disabled={isDownloading}
        size="small"
        css={{ width: 'fit-content', padding: '0 20px', marginLeft: 'auto' }}
      >
        CSV 다운
      </Button>

      <TableContainer css={desktop}>
        <table>
          <thead>
            <tr>
              <th css={{ width: '5%' }}>ID</th>
              <th>박스</th>
              <th>박스 가격</th>
              <th>티켓 ID</th>
              <th css={{ width: '40%' }}>티켓</th>
              <th>티켓 가격</th>
              <th>유저 ID</th>
              <th>유저 이름</th>
              <th>상태</th>
              <th>당첨일</th>
            </tr>
          </thead>
          <tbody>
            {gachas.map((gacha: Gacha, index: number) => (
              <tr key={gacha.gachaId}>
                <td>{gacha.gachaId}</td>
                <td>{gacha.productId}</td>
                <td>{formatNum(gacha.gachaPrice)}</td>
                <td>{gacha.ticket.ticketId}</td>
                <td>{gacha.ticket.ticketName}</td>
                <td>{formatNum(gacha.ticket.ticketPrice)}</td>
                <td>{gacha.accountId}</td>
                <td>{users[index]?.name}</td>
                <td
                  css={{
                    color: GACHA_STATUS[gacha.gachaStatus as keyof typeof GACHA_STATUS]?.color,
                  }}
                >
                  {GACHA_STATUS[gacha.gachaStatus as keyof typeof GACHA_STATUS]?.text}
                </td>
                <td>{formatDate(gacha.createdAt, 'YY-MM-DD HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      <div css={tablet}>
        {gachas.map((gacha: Gacha, index: number) => (
          <Card
            key={gacha.gachaId}
            id={`${gacha.gachaId}`}
            fields={[
              { label: '박스', value: gacha.productId },
              { label: '박스 가격', value: `${formatNum(gacha.gachaPrice)}원` },
              { label: '티켓 ID', value: gacha.ticket.ticketId },
              { label: '티켓', value: gacha.ticket.ticketName },
              { label: '티켓 가격', value: `${formatNum(gacha.ticket.ticketPrice)}원` },
              { label: '유저 ID', value: gacha.accountId },
              { label: '유저 이름', value: users[index]?.name || 'Unknown' },
            ]}
            status={GACHA_STATUS[gacha.gachaStatus as keyof typeof GACHA_STATUS]?.text}
            statusColor={GACHA_STATUS[gacha.gachaStatus as keyof typeof GACHA_STATUS]?.color}
            dateLabel="당첨일"
            dateValue={formatDate(gacha.createdAt, 'YY-MM-DD HH:mm')}
          />
        ))}
      </div>

      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </Container>
  )
}

export default Gachas

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
