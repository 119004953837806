import styled from '@emotion/styled'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useEffect, useState } from 'react'

import useAuthCheck from '@/hooks/useAuthCheck'
import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import { userState } from '@/store/auth'
import { getProducts } from '@/apis'
import { PAGE_SIZE } from '@/constants'

import Loading from '@/components/common/Loading'
import Footer from '@/components/layout/Footer'
import EventBanner from '@/components/main/EventBanner'
import Modal from '@/components/common/Modal'
import Title from '@/components/common/Title'
import ProductList from '@/components/main/Products'

const Main = () => {
  const navigate = useNavigate()
  const user = useRecoilValue(userState)
  const isAuth = useAuthCheck()
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth && !user?.phone) {
      setOpenModal(true)
    }
  }, [isAuth, user])

  const {
    data: { pages: products = [] } = {},
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['products'],
    queryFn: ({ pageParam = 0 }) => getProducts(pageParam as number, PAGE_SIZE),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageable.pageNumber + 1
      return nextPage < lastPage.totalPages ? nextPage : undefined
    },
    initialPageParam: 0,
  })

  const observerRef = useInfiniteScroll({
    callback: fetchNextPage,
    isFetchingNextPage,
    isLoading,
  })

  if (isLoading) return <Loading />

  return (
    <Container>
      <EventBanner />

      {/*<Title css={{ margin: '20px 0 12px 0' }}>최근 2주 당첨 베스트</Title>
      <RealTimeGachaDisplay />*/}

      <Title css={{ margin: '22px 0 8px 0' }}>티켓 뽑기 도전</Title>
      <ProductList products={products.flatMap((page) => page.content)} />
      <div ref={observerRef} />

      <Footer />

      {openModal && (
        <Modal
          title="휴대폰 번호를 등록해 주세요"
          desc={
            '럭키기프트의 모든 상품은\n등록한 휴대폰 번호로 발송됩니다\n\n상품 수령을 위해 핸드폰 번호를 등록해 주세요'
          }
          onConfirm={() => {
            setOpenModal(false)
            navigate('/profile')
          }}
          confirmText="확인"
          cancelText="닫기"
          onCancel={() => setOpenModal(false)}
        />
      )}
    </Container>
  )
}

export default Main

const Container = styled.div`
  position: relative;
  padding-bottom: 140px;
  display: flex;
  flex-direction: column;
`
