import ReactPixel from 'react-facebook-pixel'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useSetRecoilState } from 'recoil'

import { authCheck, kakaoLogin } from '@/apis/auth'
import { setToken } from '@/share/localStorage'
import { isAuthState, userState } from '@/store/auth'
import { useGoToHome } from '@/hooks/useGoToHome'

const KakaoCallback = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userInfo = useSetRecoilState(userState)
  const isAuth = useSetRecoilState(isAuthState)
  const goToHome = useGoToHome()
  const socialToken = searchParams.get('code')
  const redirectUrl = process.env.REACT_APP_KAKAO_REDIRECT_URL

  const { data: callbackData } = useQuery({
    queryKey: ['auth/kakao/callback', socialToken],
    queryFn: () =>
      kakaoLogin({
        socialToken: socialToken as string,
        redirectUrl: redirectUrl as string,
      }),
    enabled: !!socialToken && !!redirectUrl,
  })

  const { data: userData } = useQuery({
    queryKey: ['auth/check'],
    queryFn: () => authCheck(),
    enabled: !!callbackData?.data.accessToken,
  })

  useEffect(() => {
    if (callbackData?.success) {
      const { accessToken, refreshToken } = callbackData.data
      ReactPixel.trackCustom('럭키기프트 카카오 로그인')
      setToken('accessToken', accessToken)
      setToken('refreshToken', refreshToken)
    }
  }, [callbackData])

  useEffect(() => {
    if (userData?.success) {
      userInfo(userData.data)
      isAuth(true)
      goToHome()
    }
  }, [userData, userInfo, isAuth, navigate])

  return null
}

export default KakaoCallback
